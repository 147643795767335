export default {
  initial: "idle",
  states: {
    idle: {
      on: {
        OPEN: "busy",
      },
    },
    busy: {
      initial: "reset",
      states: {
        reset: {
          onEntry: ["resetDisplayedModal"],
          on: {
            NEXT: "prepare",
          },
        },
        prepare: {
          onEntry: ["prepareToDisplayModal"],
        },
      },
      on: {
        SHOW: "show",
        CLOSE: "idle",
      },
    },
    show: {
      initial: "reveal",
      states: {
        reveal: {
          on: {
            CLOSE: "dismiss",
          },
        },
        dismiss: {
          on: {
            ANIMATED: "dismissed",
          },
        },
        dismissed: {
          type: "final",
        },
      },
      onDone: "busy",
    },
  },
};
