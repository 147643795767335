import classic from "ember-classic-decorator";
import { inject as service } from "@ember/service";
import { computed } from "@ember/object";
import JSONAPIAdapter from "@ember-data/adapter/json-api";
import Authentication from "ember-simple-auth/mixins/data-adapter-mixin";
import applySentinelAuthorizationHeader from "eve/authorizers/sentinel";

@classic
export default class Sentinel extends JSONAPIAdapter.extend(Authentication) {
  @service
  session;

  @computed(
    "session.isAuthenticated",
    "session.data.authenticated.{auth_token,client_id}"
  )
  get headers() {
    return applySentinelAuthorizationHeader(this.session, {});
  }

  // Overwrite URL generation
  pathForType(modelName) {
    let sentinel = modelName.match(/^sentinel\/(.+?)$/);
    return super.pathForType((sentinel && sentinel[1]) || modelName);
  }
}
