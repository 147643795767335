import classic from "ember-classic-decorator";
import { A } from "@ember/array";
import RSVP from "rsvp";
import { computed } from "@ember/object";
import { or, equal } from "@ember/object/computed";
import Model, { attr, hasMany, belongsTo } from "@ember-data/model";
import DS from "ember-data";
import { translated } from "eve/utils/data/translated";

const { PromiseArray } = DS;

/**
 * @class Page
 * @module models
 * @namespace Models
 * @extends DS.Model
 */
@classic
export default class Page extends Model {
  /**
   * @property category
   * @type {DS.belongsTo}
   */
  @belongsTo
  category;

  /**
   * @property hotel
   * @type {DS.belongsTo}
   */
  @belongsTo
  hotel;

  /**
   * @property pageItems
   * @type {DS.hasMany}
   */
  @hasMany
  pageItems;

  @belongsTo("page")
  originalPage;

  /**
   * @property rooms
   * @type {DS.hasMany}
   */
  @hasMany
  rooms;

  /**
   * @property active
   * @type {Boolean}
   */
  @attr("boolean")
  active;

  /**
   * @property cleanType
   * @type {String}
   */
  @attr("string")
  cleanType;

  /**
   * @property options
   * @type {Object}
   */
  @attr
  options;

  @attr("nested-object")
  originalPageAttributes;

  /**
   * @property position
   * @type {Number}
   */
  @attr("number")
  position;

  /**
   * @property bodyTranslations
   * @type {Object}
   */
  @attr("nested-object")
  bodyTranslations;

  /**
   * @property titleTranslations
   * @type {Object}
   */
  @attr("nested-object")
  titleTranslations;

  /**
   * @property urlTranslations
   * @type {Object}
   */
  @attr("nested-object")
  urlTranslations;

  /**
   * @property weatherLocation
   * @type {String}
   */
  @attr("string")
  weatherLocation;

  /**
   * @property weatherType
   * @type {String}
   */
  @attr("string")
  weatherType;

  /**
   * @property workflow
   * @type {String}
   */
  @attr("string")
  workflow;

  /**
   * @property body
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  body;

  /**
   * @property title
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  title;

  /**
   * @property url
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  url;

  /**
   * @property isApp
   * @type {Boolean}
   * @default {Ember.ComputedProperty}
   */
  @equal("cleanType", "app")
  isApp;

  /**
   * @property isCategory
   * @type {Boolean}
   * @default {Ember.ComputedProperty}
   */
  @or("isShop", "isSurvey", "isWeather", "isInformation")
  isCategory;

  /**
   * @property isInformation
   * @type {Boolean}
   * @default {Ember.ComputedProperty}
   */
  @equal("cleanType", "information")
  isInformation;

  /**
   * @property isLink
   * @type {Boolean}
   * @default {Ember.ComputedProperty}
   */
  @equal("cleanType", "link")
  isLink;

  @equal("cleanType", "shadow")
  isShadow;

  /**
   * @property isShop
   * @type {Boolean}
   * @default {Ember.ComputedProperty}
   */
  @equal("cleanType", "shop")
  isShop;

  /**
   * @property isSurvey
   * @type {Boolean}
   * @default {Ember.ComputedProperty}
   */
  @equal("cleanType", "survey")
  isSurvey;

  /**
   * @property isWeather
   * @type {Boolean}
   * @default {Ember.ComputedProperty}
   */
  @equal("cleanType", "weather")
  isWeather;

  /**
   * @property items
   * @type {Array}
   * @default {Ember.ComputedProperty}
   */
  @computed("pageItems.@each.{item,position}")
  get items() {
    let promise = this.pageItems
      .then((pageItems) => {
        return A(pageItems.sortBy("position"));
      })
      .then((sorted) => {
        return RSVP.all(sorted.mapBy("item"));
      })
      .then((items) => {
        return A(items);
      });

    return PromiseArray.create({ promise });
  }

  /**
   * @property activeItems
   * @type {Array}
   * @default {Ember.ComputedProperty}
   */
  @computed("items.@each.active")
  get activeItems() {
    let promise = this.items.then((items) => {
      return A(items.filterBy("active"));
    });

    return PromiseArray.create({ promise });
  }

  /**
   * @property visibleItems
   * @type {Array}
   * @default {Ember.ComputedProperty}
   */
  @computed("activeItems.@each.visible")
  get visibleItems() {
    let promise = this.activeItems.then((items) => {
      return A(items.filterBy("visible"));
    });

    return PromiseArray.create({ promise });
  }
}
