import classic from "ember-classic-decorator";
import Component from "@ember/component";
import { task } from "ember-concurrency";
import { computed } from "@ember/object";
import { inject as service } from "@ember/service";
import { isExternalLinkPage } from "eve/utils/link-helpers";

/**
 * @class PageComponent
 * @namespace Components.HomeScreenTiles
 * @module components
 * @extends Ember.Component
 */
@classic
export default class Page extends Component {
  /**
   * @property router
   * @type {RoutingService}
   * @default {Ember.InjectedProperty}
   */
  @service
  router;

  /**
   * @property page
   * @type {Model.Page}
   */
  page = null;

  /**
   * @property tile
   * @type {EmberSpModels.Models.Elastic.Tile}
   */
  tile = null;

  /**
   * @property isExternalLink
   * @type {Boolean}
   */
  @computed("page.{cleanType,url}")
  get isExternalLink() {
    return isExternalLinkPage(this.page);
  }

  /**
   * @property loadPageTask
   * @type {TaskProperty}
   */
  @task(function* () {
    let page = yield this.tile.page;
    this.set("page", page);
  })
  loadPageTask;

  /**
   * @method init
   */
  init() {
    super.init(...arguments);
    this.loadPageTask.perform();
  }

  /**
   * @method click
   * @return {Any}
   */
  click() {
    if (this.isExternalLink) {
      return window.open(this.page.get("url"), "pageWindow");
    }

    return this.router.transitionTo(
      "category.page",
      this.page.get("category.id"),
      this.page.get("id")
    );
  }
}
