import GreaterThanFilterOperator from "./filter-operators/greater-than-filter-operator";
import LessThanFilterOperator from "./filter-operators/less-than-filter-operator";
import EqualToFilterOperator from "./filter-operators/equal-to-filter-operator";
import GreaterThanOrEqualToFilterOperator from "./filter-operators/greater-than-or-equal-to-filter-operator";
import LessThanOrEqualToFilterOperator from "./filter-operators/less-than-or-equal-to-filter-operator";
import NotEqualToFilterOperator from "./filter-operators/not-equal-to-filter-operator";
import InArrayFilterOperator from "./filter-operators/in-array-filter-operator";

const FILTER_OPERATOR_MAP = {
  ">": GreaterThanFilterOperator,
  "<": LessThanFilterOperator,
  "=": EqualToFilterOperator,
  ">=": GreaterThanOrEqualToFilterOperator,
  "<=": LessThanOrEqualToFilterOperator,
  "!=": NotEqualToFilterOperator,
  in: InArrayFilterOperator,
};

/**
 * Provides an interface to compare two values. Different value types are
 * transformed, to match the left side before comparing them. Based on the
 * transform required, to create compatible values, more or less intelligent
 * transformations are implemented. For example, number transformations are
 * detecting time ranges in the string (like `1 day`, `10 minutes`, ...) and
 * is transforming that to seconds.
 *
 * @class FilterOperator
 * @namespace Util.Elastic
 * @module utils
 */
export default class {
  /**
   * Returns a filter-operator instance, based on the given operator.
   *
   * ### Example
   *
   * ```js
   * FilterOperator.for('=').compare(120, '2 minutes');
   * ```
   *
   * @method for
   * @static
   * @param {String} operator
   * @return {Util.Elastic.BaseFilterOperator}
   */
  static for(operator) {
    let FilterOperatorClass = FILTER_OPERATOR_MAP[operator];
    if (!FilterOperatorClass) {
      throw new Error(`Unknown operator '${operator}'`);
    }
    return new FilterOperatorClass();
  }
}
