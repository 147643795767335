import { isEmpty } from "@ember/utils";

export default function (session, headers = {}) {
  let token = session.get("data.authenticated.auth_token");
  let clientId = session.get("data.authenticated.client_id");
  let isAuthenticated = session.isAuthenticated;

  if (isAuthenticated && !isEmpty(token)) {
    headers["Authorization"] = `Token ${token};client_id=${clientId}`;
  }

  return headers;
}
