import classic from "ember-classic-decorator";
import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { action, computed } from "@ember/object";
import { task } from "ember-concurrency";
import { extractUrlFromCSS } from "eve/utils/style-helpers";
import { getRoomPages } from "eve/utils/pages";

/**
 * @class CategoryController
 * @namespace Controllers
 * @module controllers
 * @extends Ember.Controller
 */
@classic
export default class CategoryController extends Controller {
  /**
   * @property linkHandler
   * @type {Service.LinkHandler}
   * @default {Ember.InjectedProperty}
   */
  @service
  linkHandler;

  /**
   * @property sessionInfo
   * @type {Service.LinkHandler}
   * @default {Ember.InjectedProperty}
   */
  @service
  sessionInfo;

  /**
   * @property category
   * @type {Model.Category}
   */
  category = null;

  /**
   * @property pages
   * @type {DS.HasManyArray}
   */
  pages = null;

  @computed("sessionInfo.room.id", "pages.[]")
  get filteredPages() {
    return getRoomPages(this.sessionInfo.get("room.id"), this.pages);
  }

  /**
   * @property hotelLogo
   * @type {String}
   */
  hotelLogo = extractUrlFromCSS("top-bar-logo");

  /**
   * @property loadTask
   * @type {TaskProperty}
   */
  @task(function* () {
    let category = yield this.store.findRecord("category", this.model);
    let pages = yield category.get("pages");
    this.setProperties({ category, pages });
  })
  loadTask;

  /**
   * @method goBack
   */
  @action
  goBack() {
    this.transitionToRoute("home");
    return false;
  }

  /**
   * @method handleContentClick
   * @param {Event} event
   */
  @action
  handleContentClick(event) {
    this.linkHandler.handleClick(event);
  }
}
