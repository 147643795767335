import classic from "ember-classic-decorator";
import Transform from "@ember-data/serializer/transform";
import { dasherize, classify } from "@ember/string";
const TILE_NAMESPACE_STRING = "Elastic::Tiles::";
const tileRegex = /([^::]*.)Tile$/;

/**
 * @class ElasticTileType
 * @module transforms
 * @namespace Transform
 * @extends DS.Transform
 */
@classic
export default class ElasticTileType extends Transform {
  /**
   * @method deserialize
   * @param {string} serialized format Elastic::Tiles::TextTile
   */
  deserialize(serialized) {
    let result = serialized;
    let match = serialized.match(tileRegex);
    if (match) {
      result = dasherize(match[1]);
    }
    return result;
  }

  /**
   * @method serialize
   * @param {string} deserialized i.e: text/category/page/link
   */
  serialize(deserialized) {
    return `${TILE_NAMESPACE_STRING}${classify(deserialized)}Tile`;
  }
}
