import Raven from "raven";

const URL_REGEX = /^url\((['"]?)(.*)\1\)[;]?$/;

/**
 * @method extractUrl
 * @param {String} target
 * @returns {String}
 */
export function extractUrlFromCSS(target) {
  let logoUrl = window
    .getComputedStyle(document.body)
    .getPropertyValue(`--${target}`)
    .trim();
  let [, , url = ""] = logoUrl.match(URL_REGEX) || [];

  try {
    // When setting values via javascript on dom elements the strings are escaped,
    // the following removes the unecessary escaping and ends up with a valid URL.
    url = url.replace(/\\/g, "");
    url = new URL(url).href;
  } catch (e) {
    Raven.captureException(new Error(`Called with an invalid url ${url}`));
    url = "";
  }

  return url;
}
