import classic from "ember-classic-decorator";
import Service, { inject as service } from "@ember/service";
import { classify } from "@ember/string";
import { isPresent } from "@ember/utils";
import { task, timeout } from "ember-concurrency";
import {
  getInternalLinkData,
  getLegacyLinkData,
  isAnchorTag,
  isExternalLinkPage,
} from "eve/utils/link-helpers";
import scrollTo from "eve/utils/page-scroll";
import $ from "jquery";
import Flickity from "flickity";

/**
 * @class LinkHandler
 * @namespace Services
 * @module services
 * @extends Ember.Service
 */
@classic
export default class LinkHandlerService extends Service {
  /**
   * @property router
   * @type {Ember.RouterService}
   * @default {Ember.InjectedProperty}
   */
  @service
  router;

  /**
   * @property store
   * @type {DS.Store}
   * @default {Ember.InjectedProperty}
   */
  @service
  store;

  /**
   * @property modal
   * @type {Service.Modal}
   * @default {Ember.InjectedProperty}
   */
  @service
  modal;

  /**
   * @property metrics
   * @type {EmberMetrics.Service.MetricsService}
   * @default {Ember.InjectedProperty}
   */
  @service
  metrics;

  /**
   * @method handleClick
   * @param {Node} target
   * @return {Boolean}
   */
  handleClick(event) {
    let $target = $(event.target);
    let link = $target.closest("a");

    if (this._didHandleClick(link)) {
      event.preventDefault();
      return false;
    }
  }

  /**
   * @private
   * @method _didHandleClick
   * @param {Node} link
   * @return {Boolean}
   */
  _didHandleClick(link) {
    if (link.length) {
      let [targetLink] = link;
      if (isAnchorTag(targetLink.host, targetLink.hash)) {
        return this._handleAnchorClick(targetLink.hash);
      }

      return this._handleLinkClick(targetLink);
    }
  }

  /**
   * @private
   * @method _handleLinkClick
   * @param {Node} target
   * @return {Boolean}
   */
  _handleLinkClick(link) {
    let { type, id } =
      getInternalLinkData(link.href) || getLegacyLinkData(link);

    if (isPresent(type)) {
      this.get(`_handle${classify(type)}LinkClickTask`).perform(id);
      return true;
    }
  }

  /**
   * @private
   * @method _handleAnchorClick
   * @param {HTMLAnchorElement} target
   */
  _handleAnchorClick(hash) {
    if (hash) {
      let destinationElement = document.querySelector(hash);
      scrollTo({ top: destinationElement.offsetTop });
    }
    return true;
  }

  /**
   * @private
   * @method _jumpToSliderItem
   * @param {Number} id
   */
  _jumpToSliderItem(id) {
    let navCarousel = Flickity.data(".slider-menu");
    let indexToSelect = navCarousel.cells
      .map(({ element }) => +element.id)
      .indexOf(id);
    return navCarousel.select(indexToSelect);
  }

  /**
   * @private
   * @property _handlePageLinkClickTask
   * @param {Number} id
   */
  @(task(function* (id) {
    yield timeout(100);

    let page = yield this.store.findRecord("page", id);

    if (isExternalLinkPage(page)) {
      this.trackPage(page);
      return window.open(page.get("url"), "externalLinkWindow");
    }
    let category = yield page.get("category");

    this.router.transitionTo(
      "category.page",
      category.get("id"),
      page.get("id")
    );

    this._jumpToSliderItem(id);
  }).restartable())
  _handlePageLinkClickTask;

  /**
   * @private
   * @property _handleCategoryLinkClickTask
   * @param {Number} id
   */
  @(task(function* (id) {
    yield timeout(100);
    let category = yield this.store.findRecord("category", id);
    let pages = yield category.get("sortedPages");
    yield this._handlePageLinkClickTask.perform(pages.get("firstObject.id"));
  }).restartable())
  _handleCategoryLinkClickTask;

  /**
   * @private
   * @property _handleItemLinkClickTask
   * @param {Number} id
   */
  @(task(function* (id) {
    yield timeout(100);
    let item = yield this.store.findRecord("item", id);

    this.metrics.trackEvent({
      eventCategory: "item",
      eventAction: "click",
      eventValue: item.id,
    });

    let opts = {
      model: item,
    };
    this.modal.open("page-item", opts);
  }).restartable())
  _handleItemLinkClickTask;

  /**
   * @method jumpToInternalPage
   * @param {Models.Page} id
   */
  jumpToInternalPage(id) {
    this._handlePageLinkClickTask.perform(id);
  }

  /**
   * @method trackPage
   * @param {Models.Page} page
   */
  trackPage(page) {
    this.metrics.trackPage({
      page: `/category/${page.category.get("id")}/page/${page.get("id")}`,
      title: "category.page",
    });
  }
}
