import { isEmpty } from "@ember/utils";

export function getSortedActivePages(pages) {
  return pages.filterBy("active").sortBy("position");
}

export function getRoomPages(roomId, pages) {
  return getSortedActivePages(pages).filter((page) => {
    let pageRoomsIds = page.get("rooms").mapBy("id");
    if (isEmpty(pageRoomsIds)) {
      return true;
    }

    return pageRoomsIds.includes(roomId);
  });
}
