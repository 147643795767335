import classic from "ember-classic-decorator";
import Component from "@ember/component";
import { task } from "ember-concurrency";
import { computed } from "@ember/object";
import { inject as service } from "@ember/service";
import {
  isExternalLinkPage,
  getInternalLinkData,
} from "eve/utils/link-helpers";
import { getRoomPages } from "eve/utils/pages";

/**
 * @class CategoryComponent
 * @namespace Components.HomeScreenTiles
 * @module components
 * @extends Ember.Component
 */
@classic
export default class Category extends Component {
  /**
   * @property router
   * @type {RoutingService}
   * @default {Ember.InjectedProperty}
   */
  @service
  router;

  /**
   * @property sessionInfo
   * @type {SessionInfoService}
   * @default {Ember.InjectedProperty}
   */
  @service
  sessionInfo;

  /**
   * @property page
   * @type {Model.Page}
   */
  page = null;

  /**
   * @property tile
   * @type {Model.Elastic.Tiles.Category}
   */
  tile = null;

  /**
   * @property linkHandler
   * @type {Service.LinkHandler}
   * @default {Ember.InjectedProperty}
   */
  @service
  linkHandler;

  /**
   * @property isExternalLink
   * @type {Boolean}
   */
  @computed("page.{cleanType,url}")
  get isExternalLink() {
    return isExternalLinkPage(this.page);
  }

  /**
   * @property loadInitialPageTask
   * @type {TaskProperty}
   */
  @task(function* () {
    let category = yield this.tile.category;
    if (category) {
      let roomId = yield this.get("sessionInfo.room.id");
      let sortedPages = yield category.get("sortedPages");
      let filteredPages = getRoomPages(roomId, sortedPages);
      this.set("page", filteredPages.get("firstObject"));
    }
  })
  loadInitialPageTask;

  /**
   * @method init
   */
  init() {
    super.init(...arguments);
    this.loadInitialPageTask.perform();
  }

  /**
   * @method click
   * @return {Any}
   */
  click() {
    if (this.isExternalLink) {
      return window.open(this.page.get("url"), "categoryWindow");
    }

    if (this.page.get("cleanType") === "link") {
      const { id } = getInternalLinkData(this.page.get("urlTranslations.de"));
      return this.linkHandler.jumpToInternalPage(id);
    }

    return this.router.transitionTo(
      "category.page",
      this.tile.get("category.id"),
      this.page.get("id")
    );
  }
}
