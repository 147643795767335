export default function () {
  this.transition(
    this.matchSelector("#modal-overlay"),
    this.toValue(
      (toValue, fromValue) => toValue === null || fromValue === null
    ),
    this.use("fade")
  );

  this.transition(
    this.matchSelector(".ember-modal-dialog"),
    this.toValue(
      (toValue, fromValue) => toValue !== null && fromValue !== null
    ),
    this.use("fly-to")
  );

  this.transition(
    this.matchSelector(".ember-modal-dialog"),
    this.toValue(
      (toValue, fromValue) => toValue === null || fromValue === null
    ),
    this.use("to-up"),
    this.reverse("to-down")
  );

  this.transition(
    this.hasClass("liquid-dialog-container__is-not-fullscreen"),
    this.use(
      "explode",
      {
        pick: ".ember-modal-overlay",
        use: ["fade", { maxOpacity: 0.5 }],
      },
      {
        pick: ".ember-modal-dialog",
        use: ["to-up"],
      }
    )
  );

  this.transition(
    this.hasClass("liquid-dialog-container__is-fullscreen"),
    this.toValue((toValue) => toValue !== null),
    this.use(
      "explode",
      {
        pick: ".ember-modal-overlay",
        use: ["fade", { maxOpacity: 0.5 }],
      },
      {
        pick: ".ember-modal-dialog",
        use: ["to-up"],
      }
    ),
    this.reverse(
      "explode",
      {
        pick: ".ember-modal-overlay",
        use: ["fade", { maxOpacity: 0.5 }],
      },
      {
        pick: ".ember-modal-dialog",
        use: ["to-down"],
      }
    )
  );
}
