import { initialize } from "ember-useragent/initializers/user-agent";

/**
 * Ember UserAgent initializer
 *
 * Supports auto injection of the userAgent service app-wide.
 *
 * Generated by the ember-useragent addon.
 * Customize to change injection.
 */

export default {
  name: "user-agent",
  initialize,
};
