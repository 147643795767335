import BaseFilterOperator from "./base-filter-operator";

/**
 * @class GreaterThanFilterOperator
 * @namespace Util.Elastic.filter-operators
 * @module utils
 * @extends BaseFilterOperator
 */
export default class GreaterThanFilterOperator extends BaseFilterOperator {
  _compare(left, right) {
    return left > right;
  }
}
