import classic from "ember-classic-decorator";
import RavenServiceBase from "ember-cli-deploy-sentry/services/raven";

@classic
export default class RavenService extends RavenServiceBase {
  ignoreErrors = [
    /TransitionAborted.*/,
    /.*401 Payload.*/,
    "The adapter operation was aborted",
  ];
}
