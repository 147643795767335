import classic from "ember-classic-decorator";
import Service from "@ember/service";
import { dasherize } from "@ember/string";
import makeColor from "color";
import { isEmpty } from "@ember/utils";
import cssVars from "css-vars-ponyfill";
import { task, timeout } from "ember-concurrency";

const DEFAULT_FONTS = [
  "font-bold-italic",
  "font-bold",
  "font-italic",
  "font-regular",
  "h1-font",
  "h2-font",
  "hs-tile-font",
];

/**
 * @class ThemeService
 * @module services
 * @extends Ember.Service
 * @namespace Services
 */
@classic
export default class ThemeService extends Service {
  /**
   * @private
   * @property _root
   * @type {Node}
   */
  _root = document.documentElement;

  /**
   * @private
   * @property _head
   * @type {Node}
   */
  _head = document.head;

  /**
   * @method legacyVariablesSetup
   * @param {Object} variables
   */
  legacyVariablesSetup(variables) {
    let data = new Map([
      ["hotelBackground", this._url(variables.get("splash-image-landscape"))],
      ["hotelLogo", this._url(variables.get("splash-logo"))],
      ["topBarLogo", this._url(variables.get("top-bar-logo"))],
      ["mainBtnColor", variables.get("button-background-color")],
      ["mainColor", variables.get("basic-background-color")],
      ["mainTextColor", this._rgb(variables.get("text-color"))],
      ["h1Color", variables.get("h1-color")],
      ["h2Color", variables.get("h2-color")],
    ]);

    this._generateFonts(variables);
    this._setCustomVariables(data);
  }

  /**
   * @method setupCustomCss
   */
  setupCustomCss(customCss) {
    if (isEmpty(customCss)) {
      return;
    }
    let customStylesElement = document.createElement("style");
    customStylesElement.type = "text/css";
    customStylesElement.appendChild(document.createTextNode(customCss));
    this._head.appendChild(customStylesElement);
  }

  /**
   * @method setupHotelOverrides
   * @param {Map} hotelOverrides
   */
  setupHotelOverrides(hotelOverrides) {
    return this._setCustomVariables(hotelOverrides);
  }

  /**
   * @private
   * @property _setCustomVariables
   * @param {Map} unprefixedVariables
   */
  _setCustomVariables(unprefixedVariables) {
    let rootElement = this._root;

    let variables = {};
    unprefixedVariables.forEach(
      (val, key) => (variables[`--${dasherize(key)}`] = val)
    );

    cssVars({
      rootElement,
      variables,
    });
  }

  /**
   * @method setupColorPalette
   */
  setupColorPalette() {
    return this.setupColorPaletteTask.perform();
  }

  /**
   * Following sentry error reports it seems on slow connections
   * the css is taking a long time to load, the following task will
   * keep on retrying until `--main-color` is set.
   *
   * @method setupColorPaletteTask
   */
  @(task(function* () {
    let mainBtnColor = this._extractCssProperty("--main-color");

    while (!mainBtnColor) {
      yield timeout(200);
      mainBtnColor = this._extractCssProperty("--main-color");
    }

    let colorPalette = this._generateColorPalette(mainBtnColor.trim());
    this._setCustomVariables(colorPalette);
  }).restartable())
  setupColorPaletteTask;

  /**
   * @private
   * @property _extractCssProperty
   * @param {String} propertyName
   * @return {String}
   */
  _extractCssProperty(propertyName) {
    return window
      .getComputedStyle(document.body)
      .getPropertyValue(propertyName);
  }

  /**
   * @private
   * @property _generateColorPalette
   * @param {String} baseColor
   * @return {Map}
   */
  _generateColorPalette(baseColor) {
    let color = makeColor(baseColor);
    let colorVariables = new Map();
    for (let i = 1; i < 6; i++) {
      colorVariables.set(`shadow-${i}`, color.darken(i * 0.16).string());
      colorVariables.set(`lum-${i}`, color.lighten(i * 0.08).string());
    }
    return colorVariables;
  }

  /**
   * @private
   * @property _generateFonts
   * @param {Map} variables
   */
  _generateFonts(variables) {
    let fontFaces = document.createElement("style");
    fontFaces.type = "text/css";
    DEFAULT_FONTS.forEach((font) => {
      let fontFace = document.createTextNode(`
        @font-face {
          font-family: "${font}";
          src: ${this._url(variables.get(font))} format("truetype");
        }
      `);
      fontFaces.appendChild(fontFace);
    });

    this._head.appendChild(fontFaces);
  }

  /**
   * @private
   * @method _url
   * @param {String} value
   * @return {String}
   */
  _url(value) {
    return `url('${value}')`;
  }

  /**
   * @private
   * @method _rgb
   * @param {String} value
   * @return {String}
   */
  _rgb(value) {
    let color = makeColor(value);
    return `${color.red()},${color.green()},${color.blue()}`;
  }
}
