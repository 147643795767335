import classic from "ember-classic-decorator";
import RSVP from "rsvp";
import Model, { attr, hasMany, belongsTo } from "@ember-data/model";
import DS from "ember-data";
import { computed } from "@ember/object";
import { reads } from "@ember/object/computed";
import { translated } from "eve/utils/data/translated";

const { PromiseArray, PromiseObject } = DS;

/**
 * @class Item
 * @module models
 * @namespace Models
 * @extends DS.Model
 */
@classic
export default class Item extends Model {
  @reads("itemImage")
  bannerImage;

  /**
   * @property category
   * @type {DS.belongsTo}
   */
  @belongsTo("category")
  category;

  /**
   * @property hotel
   * @type {DS.belongsTo}
   */
  @belongsTo("hotel")
  hotel;

  /**
   * @property pages
   * @type {DS.hasMany}
   */
  @hasMany("page")
  pages;

  /**
   * @property pageItems
   * @type {DS.hasMany}
   */
  @hasMany("pageItem")
  pageItems;

  /**
   * @property prices
   * @type {DS.hasMany}
   */
  @hasMany("price")
  prices;

  /**
   * @property active
   * @type {Boolean}
   */
  @attr("boolean")
  active;

  /**
   * @property bigItemImage
   * @type {String}
   */
  @attr("string")
  bigItemImage;

  /**
   * @property itemImage
   * @type {String}
   */
  @attr("string")
  itemImage;

  /**
   * @property itemHeaderImage
   * @type {String}
   */
  @attr("string")
  itemHeaderImage;

  /**
   * @property bodyTranslations
   * @type {Object}
   */
  @attr("nested-object")
  bodyTranslations;

  /**
   * @property linkTextTranslations
   * @type {Object}
   */
  @attr("nested-object")
  linkTextTranslations;

  /**
   * @property linkUrlTranslations
   * @type {Object}
   */
  @attr("nested-object")
  linkUrlTranslations;

  /**
   * @property titleTranslations
   * @type {Object}
   */
  @attr("nested-object")
  titleTranslations;

  /**
   * @property teaserTranslations
   * @type {Object}
   */
  @attr("nested-object")
  teaserTranslations;

  /**
   * @property visible
   * @type {Boolean}
   */
  @attr("boolean")
  visible;

  /**
   * @property workflow
   * @type {String}
   */
  @attr("string")
  workflow;

  /**
   * @property workflowOptions
   * @type {Object}
   */
  @attr()
  workflowOptions;

  /**
   * @property body
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  body;

  /**
   * @property linkText
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  linkText;

  /**
   * @property linkUrl
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  linkUrl;

  /**
   * @property title
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  title;

  /**
   * @property teaser
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  teaser;

  /**
   * @property currency
   * @type {DS.Model}
   */
  @reads("defaultPrice.currency")
  currency;

  /**
   * @property currency
   * @type {Number}
   */
  @reads("defaultPrice.value")
  value;

  /**
   * @property pricesInHotelCurrency
   * @type {DS.PromiseArray}
   */
  @computed("hotel", "prices", "prices.isFulfilled")
  get pricesInHotelCurrency() {
    let promise = this.hotel
      .then((hotel) => {
        return hotel && hotel.get("currency");
      })
      .then((currency) => {
        let currencyId = currency && currency.get("id");
        return RSVP.all([currencyId, this.prices]);
      })
      .then(([currencyId, prices]) => {
        return prices.filterBy("currency.id", currencyId);
      });

    return PromiseArray.create({ promise });
  }

  /**
   * @property defaultPrice
   * @type {DS.PromiseObject}
   */
  @computed("pricesInHotelCurrency", "prices.@each.id")
  get defaultPrice() {
    let promise = this.pricesInHotelCurrency.then((prices) => {
      return prices.get("firstObject");
    });

    return PromiseObject.create({ promise });
  }

  /**
   * @property workflowModel
   * @type {DS.PromiseObject}
   */
  @computed("workflow", "store")
  get workflowModel() {
    let code = this.workflow;
    let promise = this.store.findAll("workflow").then((workflows) => {
      return workflows.findBy("code", code);
    });
    return PromiseObject.create({ promise });
  }
}
