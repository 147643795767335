import classic from "ember-classic-decorator";
import { classNames, attributeBindings } from "@ember-decorators/component";
import Component from "@ember/component";
import { computed } from "@ember/object";
import mediaUrl from "eve/utils/media-url";

/**
 * @class PageItemCard
 * @module components
 * @namespace Component
 * @extends Ember.Component
 */
@classic
@classNames("page-item-card")
@attributeBindings("style")
export default class PageItemCard extends Component {
  /**
   * @property style
   * @type {String}
   */
  @computed("model.itemImage")
  get style() {
    let imageUrl = this.model.get("itemImage");
    return `--item-header-image: url(${mediaUrl(imageUrl)})`;
  }

  /**
   * @method onClick
   */
  onClick() {}
}
