import classic from "ember-classic-decorator";
import Model, { attr, hasMany, belongsTo } from "@ember-data/model";

/**
 * @class HomeScreen
 * @namespace {Model::Elastic}
 * @module models
 * @extends DS.Model
 */
@classic
export default class HomeScreen extends Model {
  /**
   * @property name
   * @type {String}
   */
  @attr("string")
  name;

  /**
   * @property filterDefinition
   * @type {Object}
   */
  @attr
  filterDefinition;

  /**
   * @property hotel
   * @type {Model.Hotel}
   */
  @belongsTo("hotel")
  hotel;

  /**
   * @property homeScreenTiles
   * @type {DS.ManyArray}
   */
  @hasMany("elastic/home-screen-tile")
  homeScreenTiles;
}
