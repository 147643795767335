import classic from "ember-classic-decorator";
import ActiveModelAdapter from "active-model-adapter";
import { inject as service } from "@ember/service";
import Authentication from "ember-simple-auth/mixins/data-adapter-mixin";
import config from "eve/config/environment";
import applySentinelAuthorizationHeader from "eve/authorizers/sentinel";
import { computed } from "@ember/object";

@classic
export default class Application extends ActiveModelAdapter.extend(
  Authentication,
  config.api
) {
  @service
  session;

  coalesceFindRequests = true;

  @computed(
    "session.isAuthenticated",
    "session.data.authenticated.{auth_token,client_id}"
  )
  get headers() {
    return applySentinelAuthorizationHeader(this.session, {});
  }
}
