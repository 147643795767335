import classic from "ember-classic-decorator";
/* eslint-disable ember/avoid-leaking-state-in-ember-objects */
import Controller from "@ember/controller";

/**
 * @class ApplicationController
 * @namespace Controller
 * @module controllers
 * @extends Ember.Controller
 */
@classic
export default class ApplicationController extends Controller {
  /**
   * @property queryParams
   * @type {Object}
   */
  queryParams = [
    {
      journeyState: "js",
    },
  ];

  /**
   * @property journeyState
   * @type {String}
   * @default during
   */
  journeyState = "during";
}
