import classic from "ember-classic-decorator";
import Component from "@ember/component";
import { classNames } from "@ember-decorators/component";
import { run } from "@ember/runloop";
import { oneWay, sum, mapBy } from "@ember/object/computed";

/**
 * @class BxShoppingCart
 * @module components
 * @extends {Ember.Component}
 * @namespace Component
 */
@classic
@classNames("bx-shopping-cart", "waves-effect")
export default class BxShoppingCart extends Component {
  /**
   * @property label
   * @type {String}
   * @default null
   */
  label = null;

  /**
   * @property isLoading
   * @type {Boolean}
   * @default false
   */
  isLoading = false;

  /**
   * @property itemCountChanged
   * @type {Boolean}
   * @default false
   */
  itemCountChanged = false;

  /**
   * @property items
   * @type {Array}
   * @default []
   */
  items = [];

  /**
   * @private
   * @property _oldItemCount
   * @type {Number}
   */
  @oneWay("items.length")
  _oldItemCount;

  /**
   * @property itemValues
   * @type {Array}
   */
  @mapBy("items", "value")
  itemValues;

  /**
   * @property itemValues
   * @type {Number}
   */
  @sum("itemValues")
  total;

  /**
   * Closure action to be called on click
   *
   * @method onClick
   */
  onClick() {}

  /**
   * @method init
   */
  init() {
    run.scheduleOnce("afterRender", this, this._setupItemCountAnimation);
    return super.init(...arguments);
  }

  /**
   * @method didRender
   */
  didRender() {
    super.didRender(...arguments);
    let oldItemCount = this._oldItemCount;
    let newItemCount = this.items.length;

    if (oldItemCount !== newItemCount) {
      this.set("itemCountChanged", true);
    }
    this.set("_oldItemCount", newItemCount);
  }

  /**
   * @private
   * @method _setupItemCountAnimation
   */
  _setupItemCountAnimation() {
    this.$(".bx-shopping-cart__item-count").on(
      `transitionend.${this.elementId}`,
      run.bind(this, this._resetItemCountAnimation)
    );
  }

  /**
   * @private
   * @method _resetItemCountAnimation
   */
  _resetItemCountAnimation() {
    this.set("itemCountChanged", false);
  }

  /**
   * @method click
   */
  click() {
    this.onClick();
  }

  /**
   * @method willDestroyElement
   */
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.$(".bx-shopping-cart__item-count").off(
      `transitionend.${this.elementId}`
    );
  }
}
