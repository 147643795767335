import classic from "ember-classic-decorator";
import Model, { attr, belongsTo } from "@ember-data/model";

@classic
export default class PageItem extends Model {
  // Relationships
  //
  @belongsTo
  item;
  @belongsTo
  page;

  // Attributes
  //
  @attr("number")
  position;
}
