import classic from "ember-classic-decorator";
import { set, get } from "@ember/object";
import { underscore, camelize } from "@ember/string";
import Transform from "@ember-data/serializer/transform";

function nestedObjectMap(object, func) {
  if (typeof object != "object") {
    return object;
  }
  if (object instanceof Array) {
    object.forEach((item) => {
      nestedObjectMap(item, func);
    });
  } else {
    for (let key in object) {
      let value = get(object, key);
      delete object[key];
      set(object, func(key), nestedObjectMap(value, func));
    }
  }
  return object;
}

@classic
export default class NestedObject extends Transform {
  deserialize(serialized) {
    return nestedObjectMap(serialized, camelize);
  }

  serialize(deserialized) {
    return nestedObjectMap(deserialized, underscore);
  }
}
