import classic from "ember-classic-decorator";
import { A } from "@ember/array";
import { deprecate } from "@ember/application/deprecations";
import RSVP from "rsvp";
import Model, { attr, hasMany, belongsTo } from "@ember-data/model";
import DS from "ember-data";
import { computed } from "@ember/object";

const { PromiseArray } = DS;

/**
 * @class Hotel
 * @namespace Models
 * @module models
 * @extends EmberSpModels.Item
 */
@classic
export default class Hotel extends Model {
  @belongsTo("currency", { inverse: "hotel" })
  currency;

  /**
   * @property defaultLocale
   * @type {Suitepad.EmberModels.Models.Locale}
   * @default {DS.BelongsToReference}
   */
  @belongsTo("locale")
  defaultLocale;

  /**
   * @property items
   * @type {Suitepad.EmberModels.Models.Item}
   * @default {DS.ManyArray}
   */
  @hasMany("items")
  items;

  /**
   * @property hotelLocales
   * @type {Suitepad.EmberModels.Models.HotelLocale}
   * @default {DS.ManyArray}
   */
  @hasMany()
  hotelLocales;

  /**
   * @property adsEnabled
   * @type {Boolean}
   */
  @attr("boolean")
  adsEnabled;

  /**
   * @property audioSettings
   * @type {EmberObject}
   */
  @attr("nested-object")
  audioSettings;

  /**
   * @property city
   * @type {String}
   */
  @attr("string")
  city;

  /**
   * @property code
   * @type {String}
   */
  @attr("string")
  code;

  /**
   * @property country
   * @type {String}
   */
  @attr("string")
  country;

  /**
   * @property defaultEmailConfirmation
   * @type {String}
   */
  @attr("string")
  defaultEmailConfirmation;

  /**
   * @property defaultEmailDeclination
   * @type {String}
   */
  @attr("string")
  defaultEmailDeclination;

  /**
   * @property defaultItemImageId
   * @type {Number}
   */
  @attr("number")
  defaultItemImageId;

  /**
   * @property defaultSmsConfirmation
   * @type {String}
   */
  @attr("string")
  defaultSmsConfirmation;

  /**
   * @property defaultSmsDeclination
   * @type {String}
   */
  @attr("string")
  defaultSmsDeclination;

  /**
   * @property deviceOrientation
   * @type {String}
   */
  @attr("string")
  deviceOrientation;

  /**
   * @property deviceWarningsEnabled
   * @type {Boolean}
   */
  @attr("boolean")
  deviceWarningsEnabled;

  /**
   * @property deviceWarningThreshold
   * @type {Number}
   */
  @attr("number")
  deviceWarningThreshold;

  /**
   * @property displayName
   * @type {String}
   */
  @attr("string")
  displayName;

  /**
   * @property emailSenderId
   * @type {String}
   */
  @attr("string")
  emailSenderId;

  /**
   * @property eveStylesheet
   * @type {String}
   */
  @attr("string")
  eveStylesheet;

  /**
   * @property hotelStarRating
   * @type {Number}
   */
  @attr("number")
  hotelStarRating;

  /**
   * @property hotelStarRatingSuperior
   * @type {Boolean}
   */
  @attr("boolean")
  hotelStarRatingSuperior;

  /**
   * @property ipAddress
   * @type {String}
   */
  @attr("string")
  ipAddress;

  /**
   * @property manifestVersion
   * @type {String}
   */
  @attr("string")
  manifestVersion;

  /**
   * @property miracastEnabled
   * @type {Boolean}
   */
  @attr("boolean")
  miracastEnabled;

  /**
   * @property name
   * @type {String}
   */
  @attr("string")
  name;

  /**
   * @property pin
   * @type {String}
   */
  @attr("string")
  pin;

  /**
   * @property smsSenderId
   * @type {String}
   */
  @attr("string")
  smsSenderId;

  /**
   * @property state
   * @type {String}
   */
  @attr("string")
  state;

  /**
   * @property stylesheet
   * @type {String}
   */
  @attr("string")
  stylesheet;

  /**
   * @property stylesheetVariables
   * @type {Map}
   */
  @attr("map")
  stylesheetVariables;

  /**
   * @property topBarIcons
   * @type {Array}
   */
  @attr
  topBarIcons;

  /**
   * @deprecated Only relevant for the very old frontend
   * @property theme
   * @type {String}
   */
  @attr("string")
  theme;

  /**
   * @property timezone
   * @type {String}
   */
  @attr("string")
  timezone;

  /**
   * @property uuid
   * @type {String}
   */
  @attr("string")
  uuid;

  /**
   * @property sortedLocales
   * @type {DS.PromiseArray}
   */
  @computed("locales")
  get sortedLocales() {
    let locales = this.locales;
    deprecate(
      "#sortedLocales is deprecated and will be removed in 2.0.0. Use #locales instead",
      false,
      {
        id: "ember-sp-models:hotel:sortedLocales",
        until: "2.0.0",
      }
    );
    return locales;
  }

  /**
   * @property locales
   * @type {DS.PromiseArray}
   */
  @computed("hotelLocales.{@each.locale,@each.position}")
  get locales() {
    let promise = this.hotelLocales
      .then((hotelLocales) => {
        return A(hotelLocales.sortBy("position"));
      })
      .then((sorted) => {
        return RSVP.all(sorted.mapBy("locale"));
      })
      .then((locales) => {
        return A(locales);
      });

    return PromiseArray.create({ promise });
  }
}
