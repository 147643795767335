import classic from "ember-classic-decorator";
import { classNames } from "@ember-decorators/component";
import Component from "@ember/component";

/**
 * @class BxSideNav
 * @module components
 * @extends Ember.Component
 * @namespace Component
 */
@classic
@classNames("bx-side-nav")
export default class BxSideNav extends Component {}
