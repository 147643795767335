/**
 * The `window.location` object can't be stubbed easily. In order to test
 * other components, depending on values of it, this class is wrapping
 * it into an object. The methods of the object can then easily be mocked
 * and the return value can be altered.
 *
 * @class WindowLocation
 * @namespace Util
 * @module utils
 */
export class WindowLocation {
  /**
   * @method constructor
   * @param {Location} location
   */
  constructor(location) {
    this.location = location;
  }

  /**
   * @method getHostName
   * @return {String} location hostname
   */
  getHostName() {
    return this.location.hostname;
  }

  /**
   * @method getHost
   * @return {String} location host
   */
  getHost() {
    return this.location.host;
  }

  /**
   * @method getQueryParams
   * @return {Map} query params
   */
  getQueryParams() {
    let [, params = ""] = (this.location.search || "").split("?");
    let parts = params.split("&").map((el) => {
      return el.split("=").map(decodeURIComponent);
    });
    return new Map(parts);
  }

  /**
   * @method setHostName
   */
  setHostName(hostName) {
    this.location.hostname = hostName;
  }

  /**
   * @method reload
   */
  reload() {
    this.location.reload();
  }
}

export default new WindowLocation(window.location);
