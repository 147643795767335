import classic from "ember-classic-decorator";
import JSONAPIAdapter from "@ember-data/adapter/json-api";
import Authentication from "ember-simple-auth/mixins/data-adapter-mixin";
import config from "eve/config/environment";
import applySentinelAuthorizationHeader from "eve/authorizers/sentinel";
import { computed } from "@ember/object";

@classic
export default class Translation extends JSONAPIAdapter.extend(
  config.charlotte,
  Authentication
) {
  @computed(
    "session.isAuthenticated",
    "session.data.authenticated.{auth_token,client_id}"
  )
  get headers() {
    return applySentinelAuthorizationHeader(this.session, {});
  }

  pathForType(modelName) {
    modelName = modelName.substr(10); // Remove the "charlotte/" in front
    return super.pathForType(modelName);
  }
}
