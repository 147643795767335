import classic from "ember-classic-decorator";
import { classNames, classNameBindings } from "@ember-decorators/component";
import Component from "@ember/component";

/**
 * @class BxCheckbox
 * @module components
 * @extends {Ember.Component}
 * @namespace Component
 */
@classic
@classNames("bx-checkbox")
@classNameBindings(
  "checked:bx-checkbox_is-checked",
  "disabled:bx-checkbox_is-disabled",
  "valid::bx-checkbox_is-invalid"
)
export default class BxCheckbox extends Component {
  /**
   * @property value
   * @type {Mixed}
   * @default null
   */
  value = null;

  /**
   * @property checked
   * @type {Boolean}
   * @default null
   */
  checked = null;

  /**
   * @property disabled
   * @type {Boolean}
   * @default null
   */
  disabled = null;

  /**
   * @property valid
   * @type {Boolean}
   * @default true
   */
  valid = true;

  /**
   * Closure action to be called on option select
   *
   * @method optionSelected
   */
  optionSelected() {}

  /**
   * @method click
   */
  click() {
    this.optionSelected(this.value);
  }
}
