import BaseFilterOperator from "./base-filter-operator";

/**
 * @class EqualToFilterOperator
 * @namespace Util.Elastic
 * @module utils
 * @extends BaseFilterOperator
 */
export default class EqualToFilterOperator extends BaseFilterOperator {
  _compare(left, right) {
    return left === right;
  }
}
