import classic from "ember-classic-decorator";
import {
  attributeBindings,
  classNames,
  classNameBindings,
} from "@ember-decorators/component";
import Component from "@ember/component";
import { computed } from "@ember/object";
import { task, timeout } from "ember-concurrency";

/**
 * @class BxBtn
 * @module components
 * @extends {Ember.Component}
 * @namespace Component
 */

@classic
@attributeBindings("href", "target")
@classNames("bx-btn")
@classNameBindings(
  "btnClass",
  "disabled:bx-btn_is-disabled:waves-effect",
  "isAnimating:bx-btn_is-animating",
  "isModalButton:bx-btn_is-modal"
)
export default class BxBtn extends Component {
  /**
   * @property label
   * @type {String}
   * @default null
   */
  label = null;

  /**
   * @property type
   * @type {String}
   * @default 'primary'
   */
  type = "primary";

  /**
   * @property size
   * @type {String}
   * @default 'large'
   */
  size = "large";

  /**
   * @property disabled
   * @type {Boolean}
   * @default false
   */
  disabled = false;

  /**
   * @property isAnimating
   * @type {Boolean}
   * @default false
   */
  isAnimating = false;

  /**
   * @property handleAnimationTask
   * @type {EmberConcurrency.Task}
   */
  @(task(function* () {
    this.set("isAnimating", true);

    yield timeout(400);
    this.set("isAnimating", false);
  }).restartable())
  handleAnimationTask;

  /**
   * @property btnClass
   * @type {String}
   */
  @computed("type", "size")
  get btnClass() {
    return `bx-btn_type_${this.type} bx-btn_size_${this.size}`;
  }

  /**
   * @method onClick
   */
  onClick() {}

  /**
   * @method click
   */
  click() {
    this.handleAnimationTask.perform();
    this.onClick();
  }
}
