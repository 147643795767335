define("eve/serializers/item", ["exports", "eve/serializers/translated-content-to-body"], function (_exports, _translatedContentToBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _translatedContentToBody.default;
    }
  });
});
