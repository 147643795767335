import classic from "ember-classic-decorator";
/* eslint-disable ember/avoid-leaking-state-in-ember-objects */
import Service from "@ember/service";
import { run } from "@ember/runloop";
import modalStateChart from "../statecharts/modal";
import $ from "jquery";
import { statechart, matchesState } from "ember-statecharts/computed";

/**
 * @class ModalService
 * @namespace Services
 * @module services
 * @extends Ember.Service
 */
@classic
export default class ModalService extends Service {
  /**
   * @property body
   * @type {Node|String}
   */
  body = document.body;

  /**
   * @property model
   * @type {Object}
   */
  model = null;

  /**
   * @property name
   * @type {String}
   */
  name = "";

  /**
   * @property openModalBodyClass
   * @type {String}
   */
  openModalBodyClass = "body_has_open-modal";

  /**
   * @property modalQueue
   * @type {Array}
   */
  modalQueue = [];

  /**
   * @method open
   * @param {String} name
   * @param {Object} options
   */
  open(name, options = {}) {
    this.modalQueue.push({
      name,
      ...options,
    });

    return this.statechart.send("OPEN");
  }

  /**
   * @method close
   */
  close() {
    return this.statechart.send("CLOSE");
  }

  /**
   * @property showModal
   * @type {Boolean}
   */
  @matchesState("show.reveal")
  showModal;

  /**
   * @property showOverlay
   * @type {Boolean}
   */
  showOverlay = false;

  /**
   * @property statechart
   * @type {EnberStatechart}
   */
  @statechart(modalStateChart, {
    actions: {
      resetDisplayedModal() {
        this.setProperties({
          name: null,
          model: null,
          showOverlay: false,
        });

        let nextEventName = "CLOSE";

        if (this.modalQueue.length > 0) {
          nextEventName = "NEXT";
        }

        let $body = $(this.body);
        $body.off("transitionend.modal");
        $body.removeClass(this.openModalBodyClass);

        return this.statechart.send(nextEventName);
      },

      prepareToDisplayModal() {
        let { name, model } = this.modalQueue.shift();

        this.setProperties({
          name,
          model,
          showOverlay: true,
        });

        let $body = $(this.body);

        run.later(() => $body.addClass(this.openModalBodyClass), 300);

        $body.on("transitionend.modal", ".modal", () => {
          this.statechart.send("ANIMATED");
        });

        // Refactor this so the component calls an action that would trigger
        // the state change.
        return run.next(() => this.statechart.send("SHOW"));
      },
    },
  })
  statechart;
}
