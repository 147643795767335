import classic from "ember-classic-decorator";
import JSONAPISerializer from "@ember-data/serializer/json-api";

@classic
export default class Sentinel extends JSONAPISerializer {
  modelNameFromPayloadKey(key) {
    if (/^sentinel-/.test(key)) {
      key = key.substr(9);
    }
    return super.modelNameFromPayloadKey(`sentinel/${key}`);
  }

  payloadKeyFromModelName(modelName) {
    return super.payloadKeyFromModelName(modelName).substr(9);
  }
}
