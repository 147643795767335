import TranslatedContentToBodySerializer from "eve/serializers/translated-content-to-body";

export default class PageSerializer extends TranslatedContentToBodySerializer {
  normalize(modelClass, resourceHash, prop) {
    if (resourceHash?.clean_type === "shadow") {
      resourceHash.original_page_id = resourceHash.original_page_attributes.id;
    }

    return super.normalize(modelClass, resourceHash, prop);
  }
}
