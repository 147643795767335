import classic from "ember-classic-decorator";
import Model, { attr, belongsTo } from "@ember-data/model";

@classic
export default class HotelLocale extends Model {
  @belongsTo()
  hotel;
  @belongsTo()
  locale;
  @attr("number")
  position;
}
