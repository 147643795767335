import classic from "ember-classic-decorator";
import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import { isEmpty } from "@ember/utils";
import UnauthenticatedRouteMixin from "ember-simple-auth/mixins/unauthenticated-route-mixin";
import getHotelCode from "eve/utils/hotel-code-extraction";
import windowLocation from "eve/utils/window-location";
import config from "eve/config/environment";

/**
 * @class LoginRoute
 * @namespace Routes
 * @module routes
 * @extends Ember.Route
 */
@classic
export default class LoginRoute extends Route.extend(
  UnauthenticatedRouteMixin
) {
  /**
   * @property session
   * @type {Service.SessionService}
   * @default {Ember.InjectedProperty}
   */
  @service
  session;

  /**
   * @method beforeModel
   * @async
   * @param {Transition} transition
   */
  async beforeModel(transition) {
    if (!this.session.get("isAuthenticated")) {
      transition.abort();
      let code = getHotelCode();
      let settings = await this.store.findRecord("hotel-setting", code);
      if (config.redirectsEnabled) {
        this._validateAndRedirectHotelHostname(settings);
      }
      this.session.authenticate(
        "authenticator:device",
        settings.get("authenticationId")
      );
    }
    return super.beforeModel(...arguments);
  }

  /**
   * @private
   * @method _validateAndRedirectHotelHostname
   */
  _validateAndRedirectHotelHostname(settings) {
    let byodHostname = settings.get("byodHostname");
    if (
      !isEmpty(byodHostname) &&
      byodHostname !== windowLocation.getHostName()
    ) {
      windowLocation.setHostName(byodHostname);
    }
  }
}
