import classic from "ember-classic-decorator";
import Store from "@ember-data/store";
import { inject as service } from "@ember/service";
import { computed } from "@ember/object";
import { alias } from "@ember/object/computed";

// TODO: Refactor the @translated decorator that is currently defined in ember-models
// in order to rely on intl's locale directly instead of having to extend the store
@classic
export default class StoreService extends Store {
  /**
   * @property intl
   * @type {EmberIntl.Service}
   * @default {Ember.InjectedProperty}
   */
  @service
  intl;

  /**
   * @property locale
   * @type {String}
   */
  @alias("intl.primaryLocale")
  locale;

  @computed("locale", "intl.locale")
  get fallbackLocales() {
    let primaryLocale = this.locale;
    let locales = this.intl.locale;
    return locales.without(primaryLocale);
  }
}
