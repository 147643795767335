import classic from "ember-classic-decorator";
import Model, { attr } from "@ember-data/model";

@classic
export default class Translation extends Model {
  @attr("string")
  hotelId;

  @attr("string")
  locale;

  @attr()
  definition;
}
