import classic from "ember-classic-decorator";
import Transform from "@ember-data/serializer/transform";
import EmberObject from "@ember/object";

/**
 * @class TranslationTransform
 * @namespace Transforms
 * @module transforms
 * @extends DS.Transform
 */
@classic
export default class Translation extends Transform {
  /**
   * @method deserialize
   * @param {Object} serialized
   * @return {Ember.Object}
   */
  deserialize(serialized) {
    return EmberObject.create(serialized);
  }

  /**
   * @method serialize
   * @param {Object} deserialized
   * @return {Object}
   */
  serialize(deserialized) {
    return deserialized;
  }
}
