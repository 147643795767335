import classic from "ember-classic-decorator";
import { classNames } from "@ember-decorators/component";
import Component from "@ember/component";
import { assert } from "@ember/debug";

/**
 * @class BxDropdownMenu
 * @module components
 * @extends Ember.Component
 * @namespace Component
 */
@classic
@classNames("bx-dropdown-menu")
export default class BxDropdownMenu extends Component {
  /**
   * @property isOpen
   * @type {Boolean}
   * @default null
   */
  isOpen = null;

  /**
   * @property selectedOption
   * @type {Object}
   * @default null
   */
  selectedOption = null;

  /**
   * @property options
   * @type {Array}
   */
  options = [];

  /**
   * Closure action to be called on option click
   *
   * @method optionSelected
   * @param {Object} option that was selected
   */
  optionSelected(/* option */) {
    assert(
      "`bx-dropdown-menu` requires a closure action for `optionSelected` you passed none."
    );
  }
}
