import classic from "ember-classic-decorator";
import Model, { attr, hasMany } from "@ember-data/model";

/**
 * @class Currency
 * @module models
 * @namespace Models
 * @extends DS.Model
 */
@classic
export default class Currency extends Model {
  /**
   * @property hotel
   * @type {DS.hasMany}
   */
  @hasMany({ inverse: "currency" })
  hotel;

  /**
   * @property name
   * @type {String}
   */
  @attr("string")
  name;

  /**
   * @property code
   * @type {String}
   */
  @attr("string")
  code;

  /**
   * @property symbol
   * @type {String}
   */
  @attr("string")
  symbol;

  /**
   * @property precision
   * @type {Number}
   * @default 2
   */
  @attr("number", { defaultValue: 2 })
  precision;
}
