import classic from "ember-classic-decorator";
import { Promise } from "rsvp";
import Model, { attr, hasMany, belongsTo } from "@ember-data/model";
import DS from "ember-data";
import { computed } from "@ember/object";

const { PromiseObject } = DS;

/**
 * @class Hotel
 * @namespace Models
 * @module models
 * @extends EmberSpModels.Item
 */
@classic
export default class HotelSetting extends Model {
  @belongsTo()
  hotel;
  @hasMany()
  locales;

  @attr("string")
  authenticationId;
  @attr("string")
  authenticationMethod;
  @attr("string")
  themeCss;
  @attr("string")
  uuid;

  /**
   * @property byodHostname
   * @type {String}
   */
  @attr("string")
  byodHostname;

  @computed("locales.firstObject")
  get defaultLocale() {
    let promise = new Promise((resolve, reject) => {
      this.locales.then((locales) => {
        resolve(locales.get("firstObject"));
      }, reject);
    });

    return PromiseObject.create({ promise });
  }
}
