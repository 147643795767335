import classic from "ember-classic-decorator";
import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { action } from "@ember/object";

/**
 * @class AuthenticatedController
 * @namespace Controllers
 * @module controllers
 * @extends Ember.Controller
 */
@classic
export default class AuthenticatedController extends Controller {
  /**
   * @property modal
   * @type {Service.Modal}
   * @default {Ember.InjectedProperty}
   */
  @service
  modal;

  /**
   * @property queryParams
   * @type {Array}
   */
  queryParams = ["lang"];

  /**
   * @property lang
   * @type {String}
   * @default null
   */
  lang = null;

  @action
  closeModal() {
    this.modal.close();
  }
}
