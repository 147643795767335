import classic from "ember-classic-decorator";
import { reads } from "@ember/object/computed";
import Model, { attr, belongsTo } from "@ember-data/model";

@classic
export default class Device extends Model {
  // Relationships
  //
  @belongsTo
  hotel;

  @belongsTo
  room;

  // Attributes
  //
  @attr("boolean")
  active;
  @attr("number")
  amqpConnections;
  @attr("number")
  appVersionCode;
  @attr("string")
  appVersionName;
  @attr("number")
  batteryLevel;
  @attr("string")
  deviceType;
  @attr
  dst;
  @attr("string")
  ipAddress;
  @attr("date")
  lastPingedAt;
  @attr("date")
  manifestVersion;
  @attr
  screenshots;
  @attr("boolean")
  testDevice;
  @attr("string")
  time;
  @attr("string")
  timezone;
  @attr("string")
  uuid;
  @attr("boolean")
  warningsEnabled;
  @attr("string")
  wifiBssid;
  @attr("number")
  wifiLevel;
  @attr("string")
  wifiSsid;

  // Properties
  //
  @reads("hotel.currency")
  currency;
  @reads("room.code")
  roomCode;
}
