import classic from "ember-classic-decorator";
import Route from "@ember/routing/route";

/**
 * @class HomeRoute
 * @namespace Routes
 * @module routes
 * @extends Ember.Route
 */
@classic
export default class HomeRoute extends Route {
  /**
   * @method setupController
   * @param {Ember.Controller} controller
   */
  setupController(controller) {
    super.setupController(...arguments);
    controller.loadTask.perform();
  }
}
