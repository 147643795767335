import classic from "ember-classic-decorator";
import Service from "@ember/service";

/**
 * @class SessionInfoService
 * @namespace Service
 * @module services
 * @extends Ember.Service
 */
@classic
export default class SessionInfoService extends Service {
  /**
   * @property hotel
   * @type {DS.Model}
   * @default null
   */
  hotel = null;

  /**
   * @property room
   * @type {DS.Model}
   * @default null
   */
  room = null;

  /**
   * @property device
   * @type {DS.Model}
   * @default null
   */
  device = null;
}
