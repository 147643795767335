import classic from "ember-classic-decorator";
import Service from "@ember/service";
import { computed } from "@ember/object";

/**
 * @const JOURNEY_STATES
 */
const JOURNEY_STATES = ["pre", "during", "post"];

/**
 * @class JourneyStateService
 * @namespace Service
 * @module services
 * @extends Ember.Service
 */
@classic
export default class JourneyService extends Service {
  /**
   * @private
   * @property _state
   * @type {String}
   * @default during
   */
  _state = "during";

  /**
   * @property state
   * @type {String}
   */
  @computed("_state")
  get state() {
    if (JOURNEY_STATES.includes(this._state)) {
      return this._state;
    }
    return "during";
  }

  /**
   * @property hasValidState
   * @type {Boolean}
   */
  @computed("_state", "state")
  get hasValidState() {
    return this._state === this.state;
  }

  /**
   * @method updateFromParams
   * @param {Object} params A params hash to read the values from
   */
  updateFromParams({ journeyState: _state }) {
    this.setProperties({ _state });
  }
}
