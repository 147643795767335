import classic from "ember-classic-decorator";
import { not } from "@ember/object/computed";
import Controller from "@ember/controller";
import { computed } from "@ember/object";
import { action } from "@ember/object";

@classic
export default class ComponentsController extends Controller {
  radioChecked = false;
  checkboxChecked = false;

  radioValue = not("radioChecked");
  checkboxValue = not("checkboxChecked");

  items = [];

  options = [
    { value: 1, label: "German" },
    { value: 2, label: "Italian" },
    { value: 3, label: "English" },
  ];

  @computed("options")
  selectedOption([option]) {
    return option;
  }

  @action
  shoppingCartClicked() {
    this.items.pushObject({ name: "potato", value: 10 });
  }
}
