import classic from "ember-classic-decorator";
import { classNames } from "@ember-decorators/component";
import Component from "@ember/component";
import { action } from "@ember/object";
import { task, timeout } from "ember-concurrency";
import { bind } from "@ember/runloop";
import $ from "jquery";
import Isotope from "isotope-layout";

/**
 * @class HomeScreen
 * @extends Ember.Component
 * @module components
 * @namespace Component
 */
@classic
@classNames("home-screen")
export default class HomeScreen extends Component {
  /**
   * @property homeScreen
   * @type {Model.Elastic.HomeScreen}
   */
  homeScreen = null;

  /**
   * @property homeScreenTiles
   * @type {Array}
   * @default []
   */
  homeScreenTiles = [];

  get isotopeOptions() {
    let root = document.documentElement;
    let gutterSpacing = "--spacing-sm";
    if (window.innerWidth > 801) {
      gutterSpacing = "--spacing-md";
    }
    let gutter = parseInt(
      getComputedStyle(root).getPropertyValue(gutterSpacing)
    );
    return {
      getSortData: {
        priority: "[data-priority] parseInt",
      },
      filter: ":not(.home-screen-tile_type_is-hidden)",
      itemSelector: ".home-screen-tile",
      masonry: {
        columnWidth: ".home-screen-tile",
        gutter,
        horizontalOrder: true,
      },
      percentPosition: true,
      sortBy: "priority",
    };
  }

  /**
   * @property setupHomeScreenTask
   */
  @task(function* () {
    let homeScreenTiles = yield this.homeScreen.get("homeScreenTiles");
    let isotope = new Isotope(this.element, this.isotopeOptions);
    this.setProperties({
      homeScreenTiles: homeScreenTiles.sortBy("priority"),
      isotope,
    });
  })
  setupHomeScreenTask;

  /**
   * @property reLayoutTask
   * @type {TaskProperty}
   */
  @(task(function* () {
    yield timeout(200);
    this.isotope.arrange(this.isotopeOptions);
  }).restartable())
  reLayoutTask;

  /**
   * @property cleanupIsotopeTask
   * @type {TaskProperty}
   */
  /* eslint-disable require-yield */
  @task(function* () {
    this.isotope.destroy();
  })
  cleanupIsotopeTask;
  /* eslint-enable require-yield */

  /**
   * @method addTile
   * @param {Components.HomeScreenTileComponent}
   */
  @action
  addTile(homeScreenTile) {
    this.isotope.appended(homeScreenTile.element);
  }

  /**
   * @method didInsertElement
   */
  async didInsertElement() {
    await this.setupHomeScreenTask.perform();
    $(window).on(
      `resize.${this.elementId}`,
      bind(this, function () {
        this.reLayoutTask.perform();
      })
    );
    return super.didInsertElement(...arguments);
  }

  /**
   * @method willDestroyElement
   */
  willDestroyElement() {
    this.cleanupIsotopeTask.perform();
    $(window).off(`resize.${this.elementId}`);
    super.willDestroyElement(...arguments);
  }
}
