import classic from "ember-classic-decorator";
import Service from "@ember/service";
import { bool } from "@ember/object/computed";
import { computed } from "@ember/object";
import { run } from "@ember/runloop";

const TIME_NAME_MAP = {
  0: "night",
  1: "night",
  2: "night",
  3: "night",
  4: "night",
  5: "morning",
  6: "morning",
  7: "morning",
  8: "morning",
  9: "morning",
  10: "morning",
  11: "morning",
  12: "afternoon",
  13: "afternoon",
  14: "afternoon",
  15: "afternoon",
  16: "afternoon",
  17: "afternoon",
  18: "evening",
  19: "evening",
  20: "evening",
  21: "night",
  22: "night",
  23: "night",
};

/**
 * The clock synchronizes to the local host's system clock and can be used to
 * display the time or to update time sensitive properties.
 * To use the clock in a template or in computed properties, bind to the clock's
 * `hour`, `minute`, or `second` properties.
 *
 * In templates:
 * ```hbs
 * {{clock.hour}}
 * {{clock.minute}}
 * {{clock.second}}
 * ```
 *
 * In computed properties:
 * ```js
 * @computed('clock.second')
 * property(second) {
 *   // this will update every second
 * }
 * ```
 *
 * @class ClockService
 * @namespace Service
 * @module services
 * @extends Ember.Service
 */
@classic
export default class ClockService extends Service {
  /**
   * @property date
   * @type {Date}
   */
  date = null;

  /**
   * @property dayOfMonth
   * @type {Number}
   */
  dayOfMonth = null;

  /**
   * @property dayOfWeek
   * @type {Number}
   */
  dayOfWeek = null;

  /**
   * @property hour
   * @type {Number}
   */
  hour = null;

  /**
   * @property minute
   * @type {Number}
   */
  minute = null;

  /**
   * @property month
   * @type {Number}
   */
  month = null;

  /**
   * @property second
   * @type {Number}
   */
  second = null;

  /**
   * @property year
   * @type {Number}
   */
  year = null;

  /**
   * Stores the next _tick, so that it can be cancelled and the clock stopped.
   *
   * @property _nextTick
   * @type {Object}
   * @private
   */
  _nextTick = null;

  /**
   * @property _isTicking
   * @type {Boolean}
   * @readonly
   * @private
   */
  @bool("_nextTick")
  _isTicking;

  /**
   * A named version of the current time of day (night, morning, afternoon,
   * evening).
   *
   * @property namedTimeOfDay
   * @type {String}
   */
  @computed("hour")
  get namedTimeOfDay() {
    return TIME_NAME_MAP[this.hour];
  }

  /**
   * @method init
   */
  init() {
    super.init(...arguments);
    this.start();
  }

  /**
   * @method start
   */
  start() {
    this._tick();
  }

  /**
   * @method stop
   */
  stop() {
    run.cancel(this._nextTick);
    this.set("_nextTick", null);
  }

  /**
   * Sets the time specific properties to the current time, and makes sure only
   * changed values are updated. This ensures, that computed properties based on
   * one of the bigger properties (like minute, hour, ...) aren't recomputed
   * every second.
   *
   * @method _setTime
   * @private
   */
  _setTime() {
    let now = new Date();
    let currentValues = this.getProperties(
      "date",
      "second",
      "minute",
      "hour",
      "dayOfMonth",
      "dayOfWeek",
      "month",
      "year"
    );
    let newValues = {
      date: now,
      second: now.getSeconds(),
      minute: now.getMinutes(),
      hour: now.getHours(),
      dayOfMonth: now.getDate(),
      dayOfWeek: now.getDay(),
      month: now.getMonth() + 1,
      year: now.getFullYear(),
    };
    for (let key in newValues) {
      if (newValues[key] === currentValues[key]) {
        delete newValues[key];
      }
    }
    this.setProperties(newValues);
  }

  /**
   * @method _tick
   * @private
   */
  _tick() {
    this._setTime();
    this.set("_nextTick", run.later(this, this._tick, 1000));
  }

  /**
   * @event willDestroy
   * @private
   */
  willDestroy() {
    this.stop();
  }
}
