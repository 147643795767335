import EmberRouter from "@ember/routing/router";
import config from "eve/config/environment";
import { task, timeout } from "ember-concurrency";
import scrollTo from "eve/utils/page-scroll";
import classic from "ember-classic-decorator";

@classic
export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;

  @(task(function* () {
    yield timeout(100);
    scrollTo({ behavior: "instant" });
  })
    .restartable()
    .on("routeDidChange"))
  scrollToViewTask;
}

Router.map(function () {
  this.route("login");
  this.route("components");
  this.route("authenticated", { path: "/" }, function () {
    this.route("home", { path: "/", resetNamespace: true });

    this.route(
      "category",
      { path: "category/:category_id", resetNamespace: true },
      function () {
        this.route("page", { path: "/page/:page_id" });
      }
    );
  });
});
