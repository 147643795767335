import classic from "ember-classic-decorator";
import {
  classNames,
  attributeBindings,
  classNameBindings,
} from "@ember-decorators/component";
import { inject as service } from "@ember/service";
import Component from "@ember/component";
import { computed } from "@ember/object";
import { task, timeout } from "ember-concurrency";

/**
 * @class HomeScreenTile
 * @module components
 * @namespace Component
 * @extends Ember.Component
 */
@classic
@attributeBindings("homeScreenTile.priority:data-priority")
@classNames("home-screen-tile")
@classNameBindings("tileTypeModifier", "tileVisibilityModifier")
export default class HomeScreenTile extends Component {
  /**
   * @property filter
   * @type {Services.Elastic.FilterService}
   * @default {Ember.InjectedProperty}
   */
  @service("elastic/filter")
  filter;

  /**
   * @property homeScreenTile
   * @type {String}
   * @default null
   */
  homeScreenTile = null;

  /**
   * @property tileType
   * @type {String}
   * @default null
   */
  tileType = null;

  /**
   * @property tileTypeModifier
   * @type {String}
   */
  @computed("tileType")
  get tileTypeModifier() {
    return `home-screen-tile_type_${this.tileType}`;
  }

  /**
   * @property tileVisibilityModifier
   * @type {String}
   */
  @computed("homeScreenTile.filterDefinition")
  get tileVisibilityModifier() {
    let filterDefinition = this.homeScreenTile.filterDefinition;
    if (this.filter.test(filterDefinition)) {
      return null;
    }
    return "home-screen-tile_type_is-hidden";
  }

  /**
   * @method addedAction
   */
  addedAction() {
    throw new Error("addedAction must be passed in!");
  }
  /**
   * @method didInsertElement
   */
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.addedAction(this);
  }

  /**
   * @method didRender
   */
  didRender() {
    super.didRender(...arguments);
    this.onFinishedRenderTask.perform();
  }

  /**
   * @method didReceiveAttrs
   */
  didReceiveAttrs() {
    super.didReceiveAttrs();
    this.preloadTileTask.perform();
  }
  /**
   * @method preloadTileTask
   */
  @(task(function* () {
    yield timeout(200);
    let tile = yield this.homeScreenTile.tile;
    this.setProperties({
      tile,
      tileType: tile.get("tileType"),
    });
  }).drop())
  preloadTileTask;
}
