import classic from "ember-classic-decorator";
import { classNames } from "@ember-decorators/component";
import Component from "@ember/component";

/**
 * @class BxLoader
 * @module components
 * @extends {Ember.Component}
 * @namespace Component
 */
@classic
@classNames("bx-loader")
export default class BxLoader extends Component {}
