import classic from "ember-classic-decorator";
import {
  attributeBindings,
  classNames,
  classNameBindings,
} from "@ember-decorators/component";
import Component from "@ember/component";
import { htmlSafe } from "@ember/string";
import { bind } from "@ember/runloop";
import { assert } from "@ember/debug";
import { computed } from "@ember/object";
import { task, timeout } from "ember-concurrency";

/**
 * @class LazyImage
 * @module components
 * @namespace Component
 * @extends Ember.Component
 */
@classic
@attributeBindings("style")
@classNames("lazy-image")
@classNameBindings("isLoaded:lazy-image_is-loaded")
export default class LazyImage extends Component {
  /**
   * @property alt
   * @type {String}
   * @default null
   */
  alt = null;

  /**
   * @property isLoaded
   * @type {Boolean}
   * @default null
   */
  isLoaded = null;

  /**
   * @property src
   * @type {String}
   * @default null
   */

  src = null;

  init() {
    super.init(...arguments);
    assert("Lazy image requires an src to load!", !!this.src);
  }

  /**
   * @property ratio
   * @type {Number}
   */
  @computed("imageWidth", "imageHeight")
  get ratio() {
    return (this.imageHeight / this.imageWidth) * 100;
  }

  /**
   * @property style
   * @type {Handlebars.SafeString}
   */
  @computed("ratio")
  get style() {
    return htmlSafe(`--image-ratio: ${this.ratio}%`);
  }

  /**
   * @method didInsertElement
   */
  didInsertElement() {
    let img = new Image();

    img.onload = bind(this, () => this.imageLoaded.perform());
    img.onerror = bind(this, this.handleFallback);
    img.src = this.src;

    return super.didInsertElement(...arguments);
  }

  /**
   * functionality: ?
   * @method handleFallback
   */
  handleFallback() {}

  /**
   * @property imageLoaded
   * @type {EmberConcurrency.Task}
   */
  @task(function* () {
    yield timeout(200);
    this.set("isLoaded", true);
  })
  imageLoaded;
}
