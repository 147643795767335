import classic from "ember-classic-decorator";
import Model, { attr, belongsTo } from "@ember-data/model";

@classic
export default class Room extends Model {
  // Relationships
  //
  @belongsTo
  hotel;

  // Attributes
  //
  @attr("boolean")
  dnd;
  @attr("boolean")
  occupied;
  @attr("boolean")
  chatVisible;
  @attr("string")
  cleaningStatus;
  @attr("string")
  code;
  @attr("string")
  jabberId;
  @attr("string")
  jabberPassword;
  @attr("string")
  miracastDevice;
  @attr("string")
  sipHost;
  @attr("string")
  sipPassword;
  @attr("string")
  sipPort;
  @attr("string")
  sipProxy;
  @attr("string")
  sipUser;
}
