import classic from "ember-classic-decorator";
import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { computed } from "@ember/object";
import { task } from "ember-concurrency";
import { extractUrlFromCSS } from "eve/utils/style-helpers";

/**
 * @class HomeController
 * @namespace Controllers
 * @module controllers
 * @extends Ember.Controller
 */
@classic
export default class HomeController extends Controller {
  /**
   * @property filter
   * @type {Services.Elastic.FilterService}
   * @default {Ember.InjectedProperty}
   */
  @service("elastic/filter")
  filter;

  /**
   * @property intl
   * @type {Service.intl}
   * @default {Ember.InjectedProperty}
   */
  @service
  intl;

  /**
   * @property localeSelectionIsOpen
   * @type {Boolean}
   * @default false
   */
  localeSelectionIsOpen = false;

  get displayName() {
    return this.intl.exists("display-name", this.intl.locale)
      ? this.intl.t("display-name", { htmlSafe: true })
      : this.model.displayName;
  }

  /**
   * @property hotelLogo
   * @type {String}
   */
  @computed("model.{stylesheetVariables,eveStylesheet}")
  get hotelLogo() {
    return extractUrlFromCSS("hotel-logo");
  }

  /**
   * @property topBarLogo
   * @type {String}
   */
  @computed("model.{stylesheetVariables,eveStylesheet}")
  get topBarLogo() {
    return extractUrlFromCSS("top-bar-logo");
  }

  /**
   * @property locales
   * @type {Array}
   */
  @computed("model.hotelLocales.[]")
  get locales() {
    let hotelLocales = this.model.get("hotelLocales");
    return hotelLocales.sortBy("position").mapBy("locale");
  }

  /**
   * @property loadTask
   * @type {TaskProperty}
   */
  @(task(function* () {
    yield this.filter.setup();
    let homeScreens = yield this.store.findAll("elastic/home-screen");
    let homeScreen = homeScreens
      .filter((homeScreen) => {
        return this.filter.test(homeScreen.get("filterDefinition"));
      })
      .get("firstObject");
    this.set("homeScreen", homeScreen);
  }).drop())
  loadTask;
}
