import classic from "ember-classic-decorator";
import Route from "@ember/routing/route";

/**
 * @class CategoryRoute
 * @namespace Routes
 * @module routes
 * @extends Ember.Route
 */
@classic
export default class CategoryRoute extends Route {
  /**
   * @method model
   */
  model({ category_id: id }) {
    return id;
  }

  /**
   * @method setupController
   * @param {Ember.Controller}
   */
  setupController(controller) {
    super.setupController(...arguments);
    controller.loadTask.perform();
  }
}
