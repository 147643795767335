import BaseFilterOperator from "./base-filter-operator";

/**
 * @class LessThanFilterOperator
 * @namespace Util.Elastic
 * @module utils
 * @extends BaseFilterOperator
 */
export default class LessThanFilterOperator extends BaseFilterOperator {
  _compare(left, right) {
    return left < right;
  }
}
