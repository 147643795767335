import { get } from "@ember/object";
import FilterOperator from "./filter-operator";

const REGEX_NODE_TYPE = /^Elastic::(.+)Node$/;

class NodeError extends Error {
  constructor(node, message) {
    super(message);
    this.node = node;
  }
}

/**
 * @method methodNameFromNodeType
 * @param {Object} node
 * @return {String} Name of the method to process the given node
 */
function methodNameFromNodeType(node) {
  if (typeof node.type !== "string") {
    throw new NodeError(node, "node type must be a string");
  }

  let match = node.type.match(REGEX_NODE_TYPE);
  if (!match) {
    throw new NodeError(node, "node type must match `/^Elastic::(.+)$/`");
  }

  return `_process${match[1]}`;
}

/**
 * @class FilterProcessor
 * @namespace Util.Elastic
 * @module utils
 */
export default class FilterProcessor {
  /**
   * @method constructor
   * @param {Object} node The node to be evaluated
   * @param {Object} env Environment for the evaluation
   * @return {Utils.Elastic.FilterProcessor}
   */
  constructor(node, env) {
    this.root = node;
    this.env = env;
  }

  /**
   * @method run
   * @return {Boolean}
   */
  run() {
    return this._process(this.root);
  }

  /**
   * @private
   * @method _process
   * @param {Object} node
   * @return {Boolean}
   */
  _process(node) {
    let processMethod = this[methodNameFromNodeType(node)];
    if (typeof processMethod !== "function") {
      throw new NodeError(node, `unknown node type '${node.type}'`);
    }

    return processMethod.call(this, node);
  }

  /**
   * @method _processFilterConjunction
   * @param {Object} node
   * @return {Boolean}
   */
  _processFilterConjunction(node) {
    switch (node.operator) {
      case "and":
        return node.filters.every(this._process.bind(this));
      case "or":
        return node.filters.any(this._process.bind(this));
      default:
        throw new NodeError(node, `Unknown operator '${node.operator}'`);
    }
  }

  /**
   * @method _processFilterComparison
   * @param {Object} node
   * @return {Boolean}
   */
  _processFilterComparison(node) {
    let propertyValue = get(this.env, node.property);
    return FilterOperator.for(node.operator).compare(propertyValue, node.value);
  }
}
