import classic from "ember-classic-decorator";
import { getOwner } from "@ember/application";
import Model, { attr } from "@ember-data/model";
import { computed } from "@ember/object";
import Ember from "ember";

const {
  $: { extend },
} = Ember;

@classic
export default class Locale extends Model {
  // Attributes
  //
  @attr("string")
  key;
  @attr("string")
  name;
  @attr({ defaultValue: () => ({}) })
  translations;

  // Computed
  //
  @computed("key")
  get translationKey() {
    let key = this.key;
    return `locales.${key}`;
  }

  @computed("translations", "key")
  get translationsWithDefaults() {
    let translations = this.translations;
    let key = this.key;

    let defaults = getOwner(this).lookupFactory(`locale:${key}`);
    return extend(true, {}, defaults, translations);
  }
}
