import classic from "ember-classic-decorator";
import EmberScreen, { breakpoint } from "ember-screen";

@classic
export default class ScreenService extends EmberScreen {
  @breakpoint("(min-width: 544px)")
  isPhone;

  @breakpoint("(min-width: 768px)")
  isTablet;

  @breakpoint("(min-width: 992px)")
  isDesktop;

  @breakpoint("(min-width: 1200px)")
  isLargeDesktop;
}
