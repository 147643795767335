import classic from "ember-classic-decorator";
import { classNames, classNameBindings } from "@ember-decorators/component";
import Component from "@ember/component";
import { bind } from "@ember/runloop";
import scrollTo from "eve/utils/page-scroll";
import { computed } from "@ember/object";

/**
 * @class StickyHeader
 * @module components
 * @extends {Ember.Component}
 * @namespace Component
 */

@classic
@classNames("sticky-header")
@classNameBindings(
  "isSticky:sticky-header_is-sticky",
  "showBackgroundGradient:sticky-header_has-gradient"
)
export default class StickyHeader extends Component {
  /**
   * @property isSticky
   * @type {Boolean}
   * @default false
   */
  isSticky = false;

  /**
   * @property threshold
   * @type {Number}
   * @default 1
   */
  threshold = 1;

  /**
   * @property dynamicBackgroundEnabled
   * @type {Boolean}
   */
  dynamicBackgroundEnabled = false;

  /**
   * @property showBackgroundGradient
   * @type {Boolean}
   */
  @computed("dynamicBackgroundEnabled", "isSticky")
  get showBackgroundGradient() {
    return this.dynamicBackgroundEnabled && !this.isSticky;
  }

  /**
   * Css selector of the dom element to be observed as it intersects the viewport
   *
   * @property target
   * @type {String} i.e: `.foo`, `#bar`
   * @default null
   */
  target = null;

  /**
   * @method init
   */
  init() {
    super.init(...arguments);
    this.observer = new IntersectionObserver(
      bind(this, this._handleIntersection),
      {
        threshold: this.threshold,
      }
    );
  }

  /**
   * @method didInsertElement
   */
  didInsertElement() {
    let targetElement = document.querySelector(this.target);
    this.observer.observe(targetElement);
    return super.didInsertElement(...arguments);
  }

  /**
   * @private
   * @method _handleIntersection
   * @param {IntersectionObserverEntry} entry
   */
  _handleIntersection([entry]) {
    this.set("isSticky", entry.intersectionRatio <= this.threshold);
  }

  /**
   * @method willDestroyElement
   */
  willDestroyElement() {
    this.observer.disconnect();
    return super.willDestroyElement(...arguments);
  }

  /**
   * @method click
   * @param {Event} e
   */
  click() {
    scrollTo();
  }
}
