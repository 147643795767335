import { helper } from "@ember/component/helper";
import { isExternalLinkPage } from "eve/utils/link-helpers";

/**
 * @method isExternalLinkPageHelper
 * @param {EmberSpModels.Models.Page}
 * @return {Boolean}
 */
export function isExternalLinkPageHelper([page], { only } = { only: false }) {
  if (only) {
    return isExternalLinkPage(page);
  }
  return page.get("cleanType") === "link";
}

export default helper(isExternalLinkPageHelper);
