export default {
  /**
   * Transforms a given value into a boolean value. Any string of 'true' (like
   * 'True', 'true', 'TRUE', ...) and the actual boolean value `true` is converted
   * to the boolean value `true`. Anything else is converted to the boolean value
   * `false`.
   *
   * @method booleanTransform
   * @param {Any} value
   * @return {Boolean}
   */
  boolean(value) {
    return String(value).toLowerCase() === "true";
  },

  /**
   * Any given value is transformed to a number or results in `NaN` if not
   * possible. The transform also accounts for durations, like `1 day`, and
   * transforms them into a number of seconds. The following ranges are supported:
   *
   * - second/seconds
   * - minute/minutes
   * - hour/hours
   * - day/days
   * - week/weeks
   *
   * ### Example
   *
   * ```js
   * numberTransform('2 minutes') === 120
   * ```
   * @method numberTransform
   * @param {Any} value
   * @return {Number}
   */
  number(value) {
    if (typeof value === "string") {
      let multiplierFor = {
        second: 1,
        minute: 60,
        hour: 3600,
        day: 3600 * 24,
        week: 3600 * 24 * 7,
      };
      let match = value.match(/^(\d+)\W*(second|minute|hour|day|week)(|s)$/);
      if (match) {
        return Number(match[1]) * multiplierFor[match[2]];
      }
    }
    return Number(value);
  },

  /**
   * @method stringTransform
   * @param {Any} value
   * @return {Boolean}
   */
  string(value) {
    return String(value);
  },

  /**
   * @method dateTransform
   * @param {Any} value
   * @return {Boolean}
   */
  date(value) {
    if (typeof value === "string") {
      return new Date(Date.parse(value));
    }

    if (typeof value === "object" && value instanceof Date) {
      return value;
    }

    return "Invalid Date";
  },

  /**
   * @method unknown
   * @param {Any} value
   * @return {Any}
   */
  unknown(value) {
    return value;
  },
};
