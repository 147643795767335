import classic from "ember-classic-decorator";
import JSONAPISerializer from "@ember-data/serializer/json-api";

@classic
export default class Charlotte extends JSONAPISerializer {
  modelNameFromPayloadKey(key) {
    return super.modelNameFromPayloadKey(`charlotte/${key}`);
  }

  payloadKeyFromModelName(modelName) {
    // Remove first 9 chars, i.e. "charlotte/"
    return super.payloadKeyFromModelName(modelName).substr(10);
  }
}
