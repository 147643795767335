import classic from "ember-classic-decorator";
import Model, { attr, belongsTo } from "@ember-data/model";

@classic
export default class Price extends Model {
  // Relationships
  //
  @belongsTo("currency")
  currency;
  @belongsTo("item")
  item;

  // Attributes
  //
  @attr("number", { defaultValue: 0 })
  value;
}
