import classic from "ember-classic-decorator";
import Route from "@ember/routing/route";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import { inject as service } from "@ember/service";
import { action } from "@ember/object";
import { run } from "@ember/runloop";
import { isEmpty } from "@ember/utils";
import config from "../config/environment";
import windowLocation from "eve/utils/window-location";
import { all, reject } from "rsvp";
import getHotelCode from "eve/utils/hotel-code-extraction";
import { task } from "ember-concurrency";

const {
  APP: { version: EVE_VERSION },
} = config;

/**
 * @class AuthenticatedRoute
 * @namespace Routes
 * @module routes
 * @extends Ember.Route
 */
@classic
export default class AuthenticatedRoute extends Route.extend(
  AuthenticatedRouteMixin
) {
  /**
   * @property queryParams
   * @type {Object}
   */
  queryParams = {
    lang: null,
  };

  /**
   * @property intl
   * @type {Emberintl.Services.intlService}
   * @default {Ember.InjectedProperty}
   */
  @service
  intl;

  /**
   * @property metrics
   * @type {EmberMetrics.Service.MetricsService}
   * @default {Ember.InjectedProperty}
   */
  @service
  metrics;

  /**
   * @property theme
   * @type {Services.ThemeService}
   * @default {Ember.InjectedProperty}
   */
  @service
  theme;

  /**
   * @property raven
   * @type {EmberCliSentry.Services.Raven}
   * @default {Ember.InjectedProperty}
   */
  @service
  raven;

  /**
   * @property sessionInfo
   * @type {Services.SessionInfoService}
   * @default {Ember.InjectedProperty}
   */
  @service
  sessionInfo;

  @service
  router;

  /**
   * @property hotel
   * @type {EmberSpModels.Models.Hotel}
   */
  hotel;

  /**
   * @property hotelCode
   * @type {String}
   */
  hotelCode = getHotelCode();

  /**
   * @method model
   * @async
   * @return {EmberSpModels.Models.Hotel}
   */
  async model() {
    try {
      let hotels = await this.store.findAll("hotel");
      return hotels.get("firstObject");
    } catch (e) {
      let [error = {}] = e.errors;
      if (error.status === "403") {
        await this.session.invalidate();
        return windowLocation.reload();
      }
      return reject(error.message);
    }
  }

  /**
   * @method afterModel
   * @async
   * @param {EmberSpModels.Hotel}
   * @return {EmberSpModels.HotelLocales}
   */
  async afterModel(hotel) {
    this._setupRaven(hotel.get("name"));
    this.setupThemeTask.perform(hotel);
    this._setupMetrics(hotel);
    await this._setupSessionInfo(hotel);
    await this.loadRemoteTranslations();
    await this._setupLocale(hotel);
  }

  async loadRemoteTranslations() {
    let translations = await this.store.findAll("charlotte/translation");

    translations.forEach((translation) => {
      let { definition, locale } = translation;
      this.intl.addTranslations(locale, definition);
    });
  }

  /**
   * @private
   * @async
   * @method _setupSessionInfo
   * @param {Models.Hotel} hotel
   */
  async _setupSessionInfo(hotel) {
    let sessionInfoService = this.sessionInfo;

    let code = this.hotelCode;
    let store = this.store;
    let settings = await store.findRecord("hotel-setting", code);
    let deviceId = settings.get("authenticationId");
    let device = await store.queryRecord("device", { uuid: deviceId });
    let room = await device.get("room");

    sessionInfoService.setProperties({ room, device, hotel });
  }

  /**
   * @private
   * @async
   * @method _setupLocale
   * @param {Models.Hotel} hotel
   */
  async _setupLocale(hotel) {
    let hotelLocales = await hotel.get("hotelLocales");
    let availableLocales = await all(
      hotelLocales.sortBy("position").mapBy("locale")
    );
    let userLocale;
    try {
      userLocale = localStorage.getItem("userSelectedLocale");
      if (isEmpty(userLocale)) throw "No previous user locale";
    } catch (e) {
      let { lang: locale } = this.paramsFor("authenticated");
      if (locale) {
        userLocale = locale;
      } else {
        let hotelLocale = await hotel.get("defaultLocale");
        userLocale = hotelLocale.get("key");
      }
    }
    let fallbackLocaleKeys = availableLocales.mapBy("key").without(userLocale);
    let locales = [userLocale, ...fallbackLocaleKeys];
    this.intl.setLocale(locales);
  }

  /**
   * @private
   * @method _setupRaven
   * @param {String} hotelName
   */
  _setupRaven(hotelName) {
    let raven = this.raven;

    raven.callRaven("setTagsContext", { version: EVE_VERSION });
    raven.callRaven("setExtraContext", { "hotel-name": hotelName });
  }

  /**
   * @method setupThemeTask
   * @param {Models.Hotel} hotel
   */
  @(task(function* (hotel) {
    let theme = this.theme;
    yield theme.setupCustomCss(hotel.get("eveStylesheet"));
    yield theme.legacyVariablesSetup(hotel.get("stylesheetVariables"));
    yield theme.setupColorPalette();
  }).restartable())
  setupThemeTask;

  /**
   * @private
   * @method _setupMetrics
   * @param {Models.Hotel} hotel
   */
  _setupMetrics(hotel) {
    this.metrics.set("context", {
      contentGroup1: `/hotel/${hotel.get("code")}`,
      language: this.intl.primaryLocale,
    });
  }

  /**
   * Tracks a page hit. The passed in parameters are required variables for a
   * Google  Analytics page hit.
   *
   * see: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
   *
   * @private
   * @method _trackPage
   */
  _trackPage() {
    let {
      "router.currentRouteName": title,
      "router.url": page,
      metrics,
    } = this.getProperties("metrics", "router.currentRouteName", "router.url");
    metrics.trackPage({ page, title });
  }

  /**
   * @method didTransition
   */
  @action
  didTransition() {
    super.init(...arguments);
    run.scheduleOnce("afterRender", this, this._trackPage);
  }
}
