import classic from "ember-classic-decorator";
import Model, { attr, belongsTo } from "@ember-data/model";

/**
 * @class HomeScreenTile
 * @namespace {Model::Elastic}
 * @module models
 * @extends DS.Model
 */
@classic
export default class HomeScreenTile extends Model {
  /**
   * @property priority
   * @type {Number}
   */
  @attr("number")
  priority;

  /**
   * @property filterDefinition
   * @type {Object}
   */
  @attr
  filterDefinition;

  /**
   * @property homeScreen
   * @type {Model.Elastic.HomeScreen}
   */
  @belongsTo("elastic/home-screen")
  homeScreen;

  /**
   * @property tile
   * @type {Model.Elastic.Tile}
   */
  @belongsTo("elastic/tile")
  tile;
}
