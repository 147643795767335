import classic from "ember-classic-decorator";
import $ from "jquery";
import { run } from "@ember/runloop";
import Authenticator from "ember-simple-auth/authenticators/base";
import config from "eve/config/environment";

const {
  sentinel: { host: sentinelHost, namespace: sentinelNamespace },
} = config;

@classic
export default class Device extends Authenticator {
  host = sentinelHost;
  namespace = sentinelNamespace;
  tokenEndpoint = "/devices/sign_in";

  authenticate(uuid) {
    return new Promise((resolve, reject) => {
      this.sendRequest({ uuid }).then(
        (data) => {
          run(null, resolve, data);
        },
        (error) => {
          run(null, reject, error.responseJSON || error.responseText);
        }
      );
    });
  }

  restore(data) {
    return data.auth_token ? Promise.resolve(data) : Promise.reject();
  }

  sendRequest(data) {
    return $.ajax({
      method: "post",
      dataType: "json",
      url: `${this.host}/${this.namespace}${this.tokenEndpoint}`,
      data,
    });
  }
}
