import classic from "ember-classic-decorator";
import { classNames, attributeBindings } from "@ember-decorators/component";
import Component from "@ember/component";
import { computed } from "@ember/object";
import mediaUrl from "eve/utils/media-url";
import { htmlSafe } from "@ember/string";

/**
 * @class ShopPageItemCard
 * @module components
 * @namespace Component
 * @extends Ember.Component
 */
@classic
@classNames("shop-page-item-card")
@attributeBindings("style")
export default class ShopPageItemCard extends Component {
  /**
   * @property style
   * @type {String}
   */
  @computed("model.itemImage")
  get style() {
    let imageUrl = this.model.get("itemImage");
    return `--item-header-image: url(${mediaUrl(imageUrl)})`;
  }

  /**
   * @property teaser
   * @type {Handlebars.SafeString}
   */
  @computed("model.teaser")
  get teaser() {
    let teaser = this.model.get("teaser");

    return htmlSafe(teaser);
  }
}
