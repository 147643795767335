import EmberObject, {
  get,
  getProperties,
  notifyPropertyChange,
  set,
} from "@ember/object";
import { isPresent } from "@ember/utils";
import { decoratorWithParams } from "@ember-decorators/utils/decorator";

/**
 * translatedAttribute - Creates a translated version of the given attribute.
 * The locale used to be get/set must be set as `locale` on the store. Also a
 * property `fallbackLocales` can be provided, to support translation fallbacks.
 *
 * ```js
 * import Model from 'ember-data/model';
 * import { translated } from 'eve/utils/data/translated';
 * import { attr } from 'ember-computed-decorators/ember-data';
 *
 * export default Model.extend({
 *   // Attributes
 *   @attr('string') slug,
 *   @attr('nested-object') titleTranslations,
 *   @attr('nested-object') contentTranslations,
 *
 *   // Translations
 *   _locale: 'en',
 *   @translated title,
 *   @translated('contentTranslations') pageContent
 * });
 * ```
 *
 * @param  {String} attribute source attribute
 * @public
 */
export const translated = decoratorWithParams(function (
  target,
  key,
  desc,
  [attribute] = []
) {
  let translationsKey = attribute ? attribute : `${key}Translations`;

  return {
    configurable: true,
    enumerable: true,
    get() {
      let {
        "store.locale": locale,
        "store.fallbackLocales": fallbackLocales = [],
      } = getProperties(this, "store.locale", "store.fallbackLocales");

      let translation;
      for (let _locale of [locale, ...fallbackLocales.without(locale)]) {
        if (isPresent(translation)) {
          break;
        }
        translation = get(this, `${translationsKey}.${_locale}`);
      }

      return translation;
    },

    set(value) {
      let current = get(this, translationsKey);
      if (!current) {
        set(this, translationsKey, EmberObject.create());
      }
      let locale = get(this, "store.locale");
      let result = set(this, `${translationsKey}.${locale}`, value);
      notifyPropertyChange(this, translationsKey);
      return result;
    },
  };
});
