define("eve/utils/statechart", ["exports", "ember-statecharts/utils/statechart"], function (_exports, _statechart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _statechart.default;
    }
  });
});
