import classic from "ember-classic-decorator";
import Component from "@ember/component";

/**
 * @class TextComponent
 * @namespace Components.HomeScreenTiles
 * @module components
 * @extends Ember.Component
 */
@classic
export default class Text extends Component {
  /**
   * @property tile
   * @type {Model.Elastic.Tile}
   */
  tile = null;
}
