import classic from "ember-classic-decorator";
import Model, { attr, hasMany, belongsTo } from "@ember-data/model";
import { translated } from "eve/utils/data/translated";

/**
 * @class Tile
 * @namespace {Model::Elastic}
 * @module models
 * @extends DS.Model
 */
@classic
export default class Tile extends Model {
  /**
   * @property category
   * @type {Model.Category}
   */
  @belongsTo
  category;

  /**
   * @property hotel
   * @type {Model.Hotel}
   */
  @belongsTo
  hotel;

  /**
   * @property page
   * @type {Model.Page}
   */
  @belongsTo
  page;

  /**
   * @property imageUrl
   * @type {String}
   */
  @attr("string")
  imageUrl;

  /**
   * @property imageWidth
   * @type {Number}
   */
  @attr("number")
  imageWidth;

  /**
   * @property imageHeight
   * @type {Number}
   */
  @attr("number")
  imageHeight;

  /**
   * @property tileType
   * @type {String}
   */
  @attr("elastic-tile-type")
  tileType;

  /**
   * @property homeScreenTiles
   * @type {DS.ManyArray}
   */
  @hasMany("elastic/home-screen-tile")
  homeScreenTiles;

  /**
   * @property teaserTranslations
   * @type {Object}
   */
  @attr("nested-object")
  teaserTranslations;

  /**
   * @property textTranslations
   * @type {Object}
   */
  @attr("nested-object")
  textTranslations;

  /**
   * @property titleTranslations
   * @type {Object}
   */
  @attr("nested-object")
  titleTranslations;

  /**
   * @property urlTranslations
   * @type {Object}
   */
  @attr("nested-object")
  urlTranslations;

  /**
   * @property teaser
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  teaser;

  /**
   * @property text
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  text;

  /**
   * @property title
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  title;

  /**
   * @property url
   * @type {String}
   * @default {Ember.ComputedProperty}
   */
  @translated
  url;
}
