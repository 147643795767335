import classic from "ember-classic-decorator";
import {
  classNames,
  classNameBindings,
  tagName,
} from "@ember-decorators/component";
import Component from "@ember/component";
import { task, timeout, waitForQueue } from "ember-concurrency";
import { action } from "@ember/object";
import Flickity from "flickity";

/**
 * @class SliderMenu
 * @module components
 * @namespace Component
 * @extends Ember.Component
 */
@classic
@classNames("slider-menu")
@classNameBindings("isPadded:slider-menu_is-padded")
@tagName("nav")
export default class SliderMenu extends Component {
  /**
   * @property isPadded
   * @type {Boolean}
   * @default null
   */
  isPadded = null;

  /**
   * @property carousel
   * @type {Flickity}
   * @default null
   */
  carousel = null;

  /**
   * @property carousel
   * @type {Object}
   */
  get carouselOptions() {
    // The following takes care of when a menu item is preselected
    // by the url on page transition/direct visit.
    let initialIndex = this.$(".slider-menu__item_is-active")
      ? this.$(".slider-menu__item_is-active").index()
      : 0;

    return {
      cellAlign: "left",
      cellSelector: ".slider-menu__item",
      contain: true,
      freeScroll: true,
      initialIndex,
      groupCells: false,
      pageDots: false,
      prevNextButtons: true,
      setGallerySize: false,
    };
  }

  /**
   * @property setupFlickity
   * @type {EmberConcurrency.Task}
   */
  @(task(function* () {
    let carousel = this.carousel;
    if (carousel) {
      // removing the elements explicitly via `carousel.remove()` is needed
      // because `carousel.destroy()` resets to the previous state in the DOM
      //
      // since the previous state is the plain rendered `<a>` elements, we need
      // to clear them explicitly, otherwise when new items are set for this
      // slider-menu component, the previous links are kept
      let elements = carousel.getCellElements();
      carousel.remove(elements);

      carousel.destroy();
    }

    yield waitForQueue("afterRender");
    carousel = new Flickity(this.element, this.carouselOptions);
    this.set("carousel", carousel);

    carousel.previous = function (isWrap, isInstant) {
      let newIndex = this.selectedIndex - 3;
      newIndex = newIndex < 0 ? 0 : newIndex;
      this.select(newIndex, isWrap, isInstant);
    };

    carousel.next = function (isWrap, isInstant) {
      let newIndex = this.selectedIndex + 3;
      newIndex =
        newIndex > this.cells.length - 1 ? this.cells.length - 1 : newIndex;

      this.select(newIndex, isWrap, isInstant);
    };

    // Flickity sometimes gets confused and we end up with the wrong gutter
    // spacing, in order to resolve this issue we need to reload the cells once
    // they have been rendered.
    yield timeout(200);
    carousel.reloadCells();
  }).restartable())
  setupFlickity;
  /**
   * The following is used when the slider item that is clicked
   * is an internal page, the link-to helper prevents default
   * so we make use of ember-link-action to select it.
   *
   * @method selectItem
   * @param {Integer} index The clicked item's index
   * @type {EmberConcurrency.Task}
   */
  @action
  selectItem(index) {
    this.carousel.select(index);
  }

  /**
   * @method onInternalLinkPageClicked
   */
  @action
  onInternalLinkPageClicked(e) {
    this.internalLinkPageClicked(e);
  }
}
