import classic from "ember-classic-decorator";
import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { htmlSafe } from "@ember/string";
import { action, computed } from "@ember/object";
import { equal } from "@ember/object/computed";
import { task } from "ember-concurrency";

/**
 * @class PageController
 * @namespace Controllers.Category
 * @module controllers
 * @extends Ember.Controller
 */
@classic
export default class PageController extends Controller {
  /**
   * @property linkHandler
   * @type {Service.LinkHandler}
   * @default {Ember.InjectedProperty}
   */
  @service
  linkHandler;

  /**
   * @property metrics
   * @type {EmberMetrics.Service.MetricsService}
   * @default {Ember.InjectedProperty}
   */
  @service
  metrics;

  /**
   * @property modal
   * @type {Service.Modal}
   * @default {Ember.InjectedProperty}
   */
  @service
  modal;

  /**
   * @property page
   * @type {Model.Page}
   */
  page = null;

  @equal("page.activeItems.length", 2)
  shouldCenterItems;

  /**
   * @property pageBody
   * @type {Handlebars.SafeString}
   */
  @computed("page.body")
  get pageBody() {
    let modelContent = this.page.body;
    return htmlSafe(modelContent);
  }

  /**
   * @property loadTask
   * @type {TaskProperty}
   */
  @task(function* () {
    let page = yield this.store.findRecord("page", this.model);

    this.set("page", page.isShadow ? yield page.get("originalPage") : page);
  })
  loadTask;

  /**
   * @method openItemModal
   * @param {EmberSpModels.Models.Item} item
   */
  @action
  openItemModal(item) {
    this._trackItemClick(item);
    let opts = {
      model: item,
    };
    this.modal.open("page-item", opts);
  }

  /**
   * @private
   * @method _trackItemClick
   * @param {EmberSpModels.Models.Item} item
   */
  _trackItemClick(item) {
    this.metrics.trackEvent({
      eventCategory: "item",
      eventAction: "click",
      eventValue: item.id,
    });
  }

  /**
   * @method handleContentClick
   * @param {Event} event
   */
  @action
  handleContentClick(event) {
    this.linkHandler.handleClick(event);
  }
}
