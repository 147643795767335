import classic from "ember-classic-decorator";
import { classNames } from "@ember-decorators/component";
import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { isEmpty, isPresent } from "@ember/utils";
import { action, computed } from "@ember/object";
import { gt } from "@ember/object/computed";

/**
 * @class LocaleSelector
 * @module components
 * @namespace Component
 * @extends Ember.Component
 */
@classic
@classNames("locale-selector")
export default class LocaleSelector extends Component {
  /**
   * @property intl
   * @type {Service.intl}
   * @default {Ember.InjectedProperty}
   */
  @service
  intl;

  /**
   * @property metrics
   * @type {EmberMetrics.Service.MetricsService}
   * @default {Ember.InjectedProperty}
   */
  @service
  metrics;

  /**
   * @property isOpen
   * @type {Boolean}
   * @default false
   */
  isOpen = false;

  /**
   * @property hasShadow
   * @type {Boolean}
   * @default false
   */
  hasShadow = false;

  /**
   * @property shouldShowLocale
   * @type {Boolean}
   * @default false
   */
  @gt("locales.length", 1)
  shouldShowLocale;

  /**
   * @property locales
   * @type {Array}
   * @default []
   */
  locales = [];

  /**
   * @property selectedLocale
   * @type {EmberModels.Locale}
   */
  @computed("_selectedLocale", "intl.primaryLocale", "locales")
  get selectedLocale() {
    return isPresent(this._selectedLocale)
      ? this._selectedLocale
      : this.locales.find(
          (locale) => locale.get("key") === this.intl.primaryLocale
        );
  }

  /**
   * @method localeSelected
   * @param {EmberModels.Locale} locale
   * @param {Event} e
   * @return {Boolean} false
   */
  @action
  localeSelected(locale, e) {
    e.stopImmediatePropagation();

    if (!isEmpty(locale)) {
      let localeKey = locale.get("key");
      this.setProperties({
        "metrics.context.language": localeKey,
        isOpen: false,
        _selectedLocale: locale,
      });

      let fallbackLocaleKeys = this.locales.mapBy("key").without(localeKey);
      let locales = [localeKey, ...fallbackLocaleKeys];

      this.intl.setLocale(locales);

      localStorage.setItem("userSelectedLocale", localeKey);
    }
    return false;
  }

  /**
   * @method toggleLocaleSelection
   * @param {Event} e
   * @return {Boolean} false
   */
  @action
  toggleLocaleSelection(e) {
    e.stopImmediatePropagation();
    if (this.shouldShowLocale) {
      this.toggleProperty("isOpen");
    }
    return false;
  }
}
