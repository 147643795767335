/**
 * @method scrollTo
 * @param  {Object} [options={}]
 */
export default function scrollTo(options = {}) {
  let { top = 0, left = 0, behavior = "smooth" } = options;

  window.scroll({
    top,
    left,
    behavior,
  });
}
