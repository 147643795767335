import classic from "ember-classic-decorator";
import { attributeBindings, classNames } from "@ember-decorators/component";
import Component from "@ember/component";
import { computed } from "@ember/object";
import mediaUrl from "eve/utils/media-url";
import { htmlSafe } from "@ember/string";
import { inject as service } from "@ember/service";
import {
  isInternalCategoryLink,
  isInternalPageLink,
  getInternalLinkData,
} from "eve/utils/link-helpers";
import { task } from "ember-concurrency";

/**
 * @class PageItemModalComponent
 * @module components
 * @namespace Component
 * @extends Ember.Component
 */
@classic
@attributeBindings("style")
@classNames("modal", "modal_type_page-item")
export default class PageItemModal extends Component {
  /**
   * @property router
   * @type {Ember.RouterService}
   * @default {Ember.InjectedProperty}
   */
  @service
  router;

  /**
   * @property store
   * @type {EmberData.StoreService}
   * @default {Ember.InjectedProperty}
   */
  @service
  store;

  /**
   * @property screen
   * @type {EmberScreen.Service}
   * @default {Ember.InjectedProperty}
   */
  @service
  screen;

  /**
   * @property body
   * @type {Handlebars.SafeString}
   */
  @computed("model.body")
  get body() {
    let body = this.model.get("body");

    return htmlSafe(body);
  }

  /**
   * @property buttonSize
   * @type {String}
   */
  @computed("screen.isLargeDesktop")
  get buttonSize() {
    return this.screen.isLargeDesktop ? "medium" : "large";
  }

  /**
   * @property style
   * @type {String}
   */
  @computed("model.itemImage")
  get style() {
    let imageUrl = this.model.get("itemImage");

    return `--header-background-url: url(${mediaUrl(imageUrl)})`;
  }

  /**
   * @method linkClickedTask
   * @param {String} url
   */
  @task(function* (url) {
    let transitionOptions = [];

    if (isInternalCategoryLink(url)) {
      let { id } = getInternalLinkData(url);
      transitionOptions = ["category", id];
    } else if (isInternalPageLink(url)) {
      let { id } = getInternalLinkData(url);
      let page = yield this.store.findRecord("page", id);
      let category = yield page.get("category");

      transitionOptions = ["category.page", category.id, id];
    }

    this.onClose();
    yield this.router.transitionTo(...transitionOptions);
  })
  linkClickedTask;
}
