import location from "./window-location";

/**
 * @const HOST_NAME_REGEX
 */
const HOST_NAME_REGEX = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;

/**
 * @const DEFAULT_HOTEL_CODE
 */
const DEFAULT_HOTEL_CODE = "demo_spl_installation";

/**
 * @method fromHostName
 * @return {String} hotel code
 */
export function fromHostName() {
  let hostName = location.getHostName();
  return HOST_NAME_REGEX.test(hostName) ? hostName : null;
}

/**
 * @method fromQueryParams
 * @return {String} hotel code
 */
export function fromQueryParams() {
  return location.getQueryParams().get("code");
}

/**
 * @method hotelCode
 * @return {String} hotel code
 */
export default function hotelCode() {
  return fromQueryParams() || fromHostName() || DEFAULT_HOTEL_CODE;
}
