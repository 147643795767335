import classic from "ember-classic-decorator";
import Transform from "@ember-data/serializer/transform";

/**
 * @class MapTransform
 * @namespace Transforms
 * @module transforms
 * @extends DS.Transform
 */
@classic
export default class MapTransform extends Transform {
  /**
   * @method deserialize
   * @param {Object} serialized
   * @return {Map}
   */
  deserialize(serialized) {
    let map = new Map();
    if (serialized instanceof Object) {
      Object.keys(serialized).forEach((key) => map.set(key, serialized[key]));
    }
    return map;
  }

  /**
   * @method serialize
   * @param {Map} deserialized
   * @return {Object}
   */
  serialize(deserialized) {
    if (!(deserialized instanceof Map)) {
      return null;
    }
    let result = {};
    deserialized.forEach((value, key) => (result[key] = value));
    return result;
  }
}
