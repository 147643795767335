import transformMap from "../filter-operator-transforms";

/**
 * @class BaseFilterOperator
 * @namespace Util.Elastic
 * @module utils
 */
export default class BaseFilterOperator {
  /**
   * The left side is used to determine the data type, the right side is then
   * transformed to the same type before comparing them.
   *
   * @method compare
   * @param {Any} left
   * @param {Any} right
   * @return {Boolean}
   */
  compare(left, right) {
    let transform = this._findTransform(left);
    if (Array.isArray(right)) {
      right = right.map((i) => transform(i));
    } else {
      right = transform(right);
    }
    return this._compare(left, right);
  }

  /**
   * @private
   * @method _compare
   * @param {Any} left
   * @param {Any} right
   * @return {Boolean}
   */
  _compare() {
    throw new Error("Not implemented");
  }

  /**
   * @private
   * @method _findTransform
   * @param {Any} value
   * @return {Function}
   */
  _findTransform(value) {
    let type = typeOfValue(value);
    return transformMap[type];
  }
}

const PROPERTY_TYPES = ["boolean", "number", "string", "date"];
const PROPERTY_TYPE_MAPPINGS = {
  Date: "date",
};

/**
 * @method typeOfValue
 * @param {Any} value
 * @return {String}
 */
export function typeOfValue(value) {
  let type = typeof value;

  if (PROPERTY_TYPES.indexOf(type) > -1) {
    return type;
  }

  if (type === "object" && PROPERTY_TYPE_MAPPINGS[value.constructor.name]) {
    return PROPERTY_TYPE_MAPPINGS[value.constructor.name];
  }

  return "unknown";
}
