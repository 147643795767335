import BaseFilterOperator from "./base-filter-operator";

/**
 * @class NotEqualToFilterOperator
 * @namespace Util.Elastic
 * @module utils
 * @extends BaseFilterOperator
 */
export default class NotEqualToFilterOperator extends BaseFilterOperator {
  _compare(left, right) {
    return left !== right;
  }
}
