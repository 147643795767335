import classic from "ember-classic-decorator";
import { readOnly } from "@ember/object/computed";
import Service, { inject as service } from "@ember/service";
import EmberObject, { defineProperty } from "@ember/object";
import FilterProcessor from "eve/utils/elastic/filter-processor";
import { isEmpty } from "@ember/utils";

const KEYS = {
  reservation: [
    "arrival",
    "departure",
    "duration",
    "bookingChannel",
    "companyName",
    "groupName",
    "groupNumber",
  ],
  guest: [
    "firstName",
    "lastName",
    "guestId",
    "language",
    "gender",
    "dateOfBirth",
    "country",
  ],
  room: ["code", "cleaningStatus", "occupied", "dnd", "chatVisible"],
  device: ["uuid", "active", "testDevice"],
  clock: [
    "namedTime",
    "date",
    "dayOfMonth",
    "dayOfWeek",
    "hour",
    "minute",
    "month",
    "second",
    "year",
  ],
  journey: ["state"],
};

/**
 * Creates a read only computed property for the given property name. The
 * property is linked to the property on the modules _base property.
 *
 * @method envLink
 * @param {Ember.Object} env The environment module to create the computed property on
 * @param {String} propName The name of the property to link
 */
function envLink(env, propName) {
  defineProperty(env, propName, readOnly(`_base.${propName}`));
}

/**
 * Provides an interface for executing a given filter. The environment has to be
 * generated before requesting an evaluation.
 *
 * Example
 * -------
 *
 * ```js
 * let filter = this.get('filterService');
 * await filter.setup();
 * filter.test(...);
 * ```
 *
 * @todo Provide a method to define a computed property for the filter
 *
 * ```js
 * HomeScreenTile.extends({
 *   filter: service(),
 *   init() {
 *     this._super(...arguments);
 *     this.get('filter').computed(this, 'visible', filterDefinition);
 *   }
 * });
 * ```
 *
 * @class FilterService
 * @namespace Service.Elastic
 * @module services
 * @extends Ember.Service
 */
@classic
export default class FilterService extends Service {
  /*
   * TODO Setup services to construct a proper environment
   * reservation: service(),
   * device: service(),
   * @alias('reservation.guest') guest: null,
   * @alias('device.room') room: null,
   * clock: service()
   */

  /**
   * @property clock
   * @type {Service.ClockService}
   * @default {Ember.InjectedProperty}
   */
  @service
  clock;

  /**
   * @property journey
   * @type {Service.JourneyService}
   * @default {Ember.InjectedProperty}
   */
  @service
  journey;

  filterProcessor = FilterProcessor;

  /**
   * Is setting up the filter service, by generating an environment used for the
   * filtering.
   *
   * @method setup
   * @param {Boolean} force Force re-building the environment (default: false)
   * @async
   */
  async setup(force = false) {
    if (typeof this._env === "object" && force === false) {
      return;
    }
    let env = EmberObject.create();
    for (let key in KEYS) {
      let envModule = EmberObject.create({ _base: await this.get(key) });
      KEYS[key].forEach(envLink.bind(undefined, envModule));
      env.set(key, envModule);
    }
    this.set("_env", env);
  }

  /**
   * Is testing the given filter, using the environment created in the last
   * setup call.
   *
   * @method test
   * @param {Object} filter Definition of the filter to evaluate
   * @return {Boolean}
   */
  test(filter) {
    if (isEmpty(filter)) {
      return true;
    }
    let processor = new this.filterProcessor(filter, this._env);
    return processor.run();
  }
}
