import BaseFilterOperator from "./base-filter-operator";

/**
 * @class InArrayFilterOperator
 * @namespace Util.Elastic
 * @module utils
 * @extends BaseFilterOperator
 */
export default class InArrayFilterOperator extends BaseFilterOperator {
  _compare(left, right) {
    return right.includes(left);
  }
}
