import classic from "ember-classic-decorator";
import Model, { attr, hasMany, belongsTo } from "@ember-data/model";
import DS from "ember-data";
import { computed } from "@ember/object";
import { filterBy, reads, notEmpty } from "@ember/object/computed";

const { PromiseArray } = DS;
/**
 * @class Category
 * @extends DS.Model
 * @namespace Models
 * @module model
 * @public
 */
@classic
export default class Category extends Model {
  /**
   * @public
   * @property hotel
   * @type {DS.PromiseObject}
   */
  @belongsTo
  hotel;

  /**
   * @public
   * @property pages
   * @type {DS.PromiseManyArray}
   */
  @hasMany
  pages;

  /**
   * @public
   * @property adsEnabled
   * @type {Boolean}
   */
  @attr("boolean", { defaultValue: true })
  adsEnabled;

  /**
   * @public
   * @property confirmationMethods
   * @type {String}
   */
  @attr("string", { defaultValue: "call_to_room,email,sms" })
  confirmationMethods;

  /**
   * @public
   * @property title
   * @type {String}
   */
  @attr("string")
  title;

  /**
   * @public
   * @property code
   * @type {String}
   */
  @attr("string")
  code;

  /**
   * @public
   * @property cron
   * @type {String}
   */
  @attr("string")
  cron;

  /**
   * @public
   * @property datetimeSelectable
   * @type {Boolean}
   */
  @attr("boolean")
  datetimeSelectable;

  /**
   * @public
   * @property hasDialog
   * @type {Boolean}
   * @deprecated This can be done through rattle events
   */
  @attr("boolean")
  hasDialog;

  /**
   * @public
   * @property shopPageWorkflow
   * @type {String}
   * @deprecated Shop pages should not rely on a workflow in the future
   */
  @attr("string")
  shopPageWorkflow;

  /**
   * @public
   * @property defaultPage
   * @type {Page}
   */
  @reads("sortedPages.firstObject")
  defaultPage;

  /**
   * @public
   * @property shopPages
   * @type {Array}
   */
  @filterBy("sortedPages", "cleanType", "shop")
  shopPages;

  /**
   * @public
   * @property isShop
   * @type {Boolean}
   */
  @notEmpty("shopPages")
  isShop;

  /**
   * @public
   * @property activePages
   * @type {DS.PromiseArray}
   */
  @computed("pages.@each.active")
  get activePages() {
    let promise = this.pages.then((pages) => {
      return pages.filterBy("active");
    });
    return PromiseArray.create({ promise });
  }

  /**
   * @public
   * @property sortedPages
   * @type {DS.PromiseArray}
   */
  @computed("activePages", "pages.@each.position")
  get sortedPages() {
    let promise = this.activePages.then((pages) => {
      return pages.sortBy("position");
    });
    return PromiseArray.create({ promise });
  }
}
