import { isEmpty, isPresent } from "@ember/utils";
import windowLocation from "eve/utils/window-location";
import $ from "jquery";

/**
 * @const EXTERNAL_LINK_REGEX
 */
const EXTERNAL_LINK_REGEX = /^(?:(?:https:|http:|ftp:)?\/\/|(?:tel|mailto):)/;

/**
 * @const INTERNAL_LINK_REGEX
 */
const INTERNAL_LINK_REGEX =
  /^(?:(?:ted|suitepad|eve):\/\/(category|item|page)\/(.*))$/;

/**
 * @method getInternalLinkData
 * @param {String} url
 * @return {String}
 */
export function getInternalLinkData(url) {
  let match = String(url).match(INTERNAL_LINK_REGEX);
  if (!match) {
    return undefined;
  }
  return { type: match[1], id: match[2] };
}

/**
 * @method isExternalLinkPage
 * @param {EmberSpModels.Models.Page} page
 * @return {Boolean}
 */
export function isExternalLinkPage(page) {
  let { cleanType, url } = page;
  return cleanType === "link" && isExternalLink(url);
}

/**
 * @method isExternalLink
 * @param {String} url
 * @return {Boolean}
 */
export function isExternalLink(url) {
  return !!String(url).match(EXTERNAL_LINK_REGEX);
}

/**
 * @method isInternalCategoryLink
 * @param {String} url
 * @return {Boolean}
 */
export function isInternalCategoryLink(url) {
  let { type } = getInternalLinkData(url) || {};
  return type === "category";
}

/**
 * @method isInternalPageLink
 * @param {String} url
 * @return {Boolean}
 */
export function isInternalPageLink(url) {
  let { type } = getInternalLinkData(url) || {};
  return type === "page";
}

/**
 * @method isAnchorTag
 * @param {HTMLAnchorElement} target
 */
export function isAnchorTag(host, hash) {
  return host === windowLocation.getHost() && !isEmpty(hash);
}

/**
 * @method getLegacyLinkData
 * @param {Node} target
 * @return {Object}
 */
export function getLegacyLinkData(target) {
  let $target = $(target);
  if (isPresent($target.data("page"))) {
    return { type: "page", id: $target.data("page") };
  }
  if (isPresent($target.data("category"))) {
    return { type: "category", id: $target.data("category") };
  }
  return {};
}
