import classic from "ember-classic-decorator";
import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import { next } from "@ember/runloop";
import ApplicationRouteMixin from "ember-simple-auth/mixins/application-route-mixin";

/**
 * @class ApplicationRoute
 * @namespace Route
 * @module routes
 * @extends Ember.Route
 */
@classic
export default class ApplicationRoute extends Route.extend(
  ApplicationRouteMixin
) {
  /**
   * @property queryParams
   * @type {Object}
   */
  queryParams = {
    journeyState: {
      refreshModel: true,
    },
  };

  /**
   * @property routeAfterAuthentication
   * @type {String}
   * @default home
   */
  routeAfterAuthentication = "home";

  /**
   * @property journey
   * @type {Service.JourneyService}
   * @default {Ember.InjectedProperty}
   */
  @service
  journey;

  /**
   * @property theme
   * @type {Service.ThemeService}
   * @default {Ember.InjectedProperty}
   */
  @service
  theme;

  /**
   * @method model
   * @param {Object} params
   */
  model(params) {
    this.journey.updateFromParams(params);
  }

  /**
   * Setting the variable is wrapped in a `run.next`, since the setup must be
   * complete, before a property update on the controller is reflected in the
   * URL (see: https://github.com/emberjs/ember.js/issues/5465).
   *
   * @method setupController
   * @param {Ember.Controller} controller
   */
  setupController(controller) {
    super.setupController(...arguments);
    let journey = this.journey;
    if (!journey.get("hasValidState")) {
      next(() => controller.set("journeyState", journey.get("state")));
    }
  }
}
