import BaseFilterOperator from "./base-filter-operator";

/**
 * @class GreaterThanOrEqualToFilterOperator
 * @namespace Util.Elastic
 * @module utils
 * @extends BaseFilterOperator
 */
export default class GreaterThanOrEqualToFilterOperator extends BaseFilterOperator {
  _compare(left, right) {
    return left >= right;
  }
}
